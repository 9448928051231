












import {Component, Vue} from "vue-property-decorator";
import CustomerRegisterForm from "@/components/domains/account/register/CustomerRegisterForm.vue";
import PICRegisterForm from "@/components/domains/account/register/PICRegisterForm.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";

@Component({
  components: {DefaultTemplate, PICRegisterForm, RegisterForm: CustomerRegisterForm, GeneralField}
})
export default class PICResigterPage extends Vue {
  name = "PICResigterPage";
}
