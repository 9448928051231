
























import {Component, Prop, Vue} from "vue-property-decorator";
import SearchTable from "@/components/infrastructures/molecules/SearchTable.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import CreditCardService from "@/components/domains/payment/creditcard/creditCardService";

@Component({
  components: {SearchTable}
})
export default class CreditCardSearchTable extends Vue {

  name = "CreditCardSearchTable.vue";
  @Prop() private value!: { id: string }[];

  // cards info
  @Prop({default: false}) showRemove: boolean;
  @Prop({default: true}) showSelect: boolean;

  // fetch items
  items = [];
  loading = true;

  // local args
  headers = [
    {text: "ブランド", value: "brand"},
    {text: "下4桁", value: "last4"},
    {text: "有効月", value: "expMonth"},
    {text: "有効年", value: "expYear"},
  ];

  beforeMount() {

    if (this.showRemove) {
      this.headers.push({text: "削除", value: "remove"});
    }

    this.fetchAll();
  }

  fetchAll() {

    this.loading = true;
    httpResource.get(`credit-card/fetch-all`)
        .then((response) => {
          this.items = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
  }

  private remove(id: string) {
    CreditCardService.delete(id)
        .then(() => {
          alert("クレジットカードの削除に成功しました。");
          this.$router.go(0);
        })
        .catch(error => {
          alert(error.response.data.message);
        });
  }

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  changeSelection(card: any) {
    this.$emit('change', card)
  }
}

