






















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import DateFactory from "@/components/infrastructures/functions/time/dateFactory";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {User} from "@/components/domains/authorization/user";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";

@Component({
  components: {KawaiiButton, KawaiiDialog}
})
export default class BaseInformation extends Vue {
  name = "BaseInformation";

  @Prop() user!: User;

  age = -1;

  days: {}[] = [];

  // dialog
  dialog = false;
  dialogMessage = '';

  txtMailAddress = new FieldData()
      .withId('email')
      .withType("text_field");

  slctBirthdayYYYY = new FieldData()
      .withId('birthday_yyyy')
      .withLabel('年')
      .withType("select");

  slctBirthdayMM = new FieldData()
      .withId('birthday_mm')
      .withLabel('月')
      .withType("select");

  slctBirthdayDD = new FieldData()
      .withId('birthday_dd')
      .withLabel('日')
      .withType("select");

  slctGender = new FieldData()
      .withId('gender')
      .withValue('')
      .withRequires(false)
      .withSelection([
        {value: "0", text: '女性'},
        {value: "1", text: '男性'},
        {value: "2", text: 'その他'},
      ])
      .withType("select");

  mounted() {
    this.watchUser();
  }

  @Watch('user')
  watchUser() {

    // ユーザー情報から各種データを入れる
    const profile = this.user.profile;

    this.slctBirthdayYYYY.value = profile.birthYear;
    this.slctBirthdayMM.value = profile.birthMonth;
    this.slctBirthdayDD.value = profile.birthDay;
    this.slctGender.value = profile.gender;
    this.txtMailAddress.value = this.user.email;

    this.updateDays();
    this.updateAge();
  }

  get getYears() {

    const year = DateFactory.newDate().getUTCFullYear();
    const range = 120;
    const startYear = year - range - 1;

    return Array.from({length: range}, (x, i) => i + 1 + startYear).map(x => {
      return {text: x + '年', value: x};
    });
  }

  get getMonths() {

    return Array.from({length: 12}, (x, i) => i + 1).map(x => {
      return {text: x + '月', value: x};
    });
  }

  get getDays() {

    const daysInMonth = new Date(Number.parseInt(this.slctBirthdayYYYY.value), Number.parseInt(this.slctBirthdayMM.value), 0).getDate();
    this.days = Array.from({length: daysInMonth}, (x, i) => i + 1).map(x => {
      return {text: x + '日', value: x};
    });

    return this.days;
  }

  updateAge() {
    const today = DateFactory.newDate();
    const birthday = new Date(
        Number.parseInt(this.slctBirthdayYYYY.value),
        Number.parseInt(this.slctBirthdayMM.value),
        Number.parseInt(this.slctBirthdayDD.value));

    const thisYearsBirthday = new Date(today.getFullYear(), birthday.getMonth() - 1, birthday.getDate());

    let age = today.getFullYear() - birthday.getFullYear();
    if (today < thisYearsBirthday) {
      // 今年まだ誕生日が来ていない
      age--;
    }

    this.age = age;
  }

  updateDays() {

    const daysInMonth = new Date(Number.parseInt(this.slctBirthdayYYYY.value), Number.parseInt(this.slctBirthdayMM.value), 0).getDate();

    this.days = Array.from({length: daysInMonth}, (x, i) => i + 1).map(x => {
      return {text: x + '日', value: x};
    });
  }

  clickUpdate() {

    const data = {
      userId: this.user.userId,
      profile: {
        birthYear: this.slctBirthdayYYYY.value,
        birthMonth: this.slctBirthdayMM.value,
        birthDay: this.slctBirthdayDD.value,
        gender: this.slctGender.value
      }
    };

    this.dialog = false;
    httpResource.post('/profile/update', data)
        .then(() => {
          this.$store.dispatch(AuthActionTypes.authenticateUser);
          this.user = this.$store.getters[AuthGetterTypes.getCurrentUser];
          this.dialogMessage = '更新に成功しました。';
        })
        .catch(() => {
          this.dialogMessage = '更新に失敗しました。';
        })
        .finally(() => {
          this.dialog = true;
        });
  }

  @Watch('slctBirthdayYYYY.value')
  watchYear() {
    this.updateDays();
    this.updateAge();
  }

  @Watch('slctBirthdayMM.value')
  watchMonth() {
    this.updateDays();
    this.updateAge();
  }

  @Watch('slctBirthdayDD.value')
  watchDay() {
    this.updateAge();
  }
}
