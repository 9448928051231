import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import DateFactory from "@/components/infrastructures/functions/time/dateFactory";

export default class StopWatch {

    /*
     * 定数
     */
    private RUN = 1;				// 動作中
    private STOP = 0;				// 停止中

    private stopWatchDateTime: StopWatchDateTime;

    private theFirstStartDate: Date // インスタンス生成後、初めてスタートした日付時刻
    private stopDate: Date // ストップした日付時刻

    private mode: number;					// ストップウォッチのモード	RUN/STOP
    private startTime: number;				// スタートした時刻
    private nowTime: number;				// ストップした時刻
    private addTime: number;				// 経過時間（ストップウォッチ再開時に加算する）
    private millisec: number;					// 1000分の1秒
    private sec100: number;					// 100分の1秒
    public sec: number;						// 秒
    public min: number;						// 分
    public hour: number;					// 時
    private gmt: number;						// タイムゾーンのオフセット値
    private diff: Date;

    constructor() {
        this.resetStopWatch();
    }

    get getDiff(): Date {
        return this.diff;
    }

    public resetStopWatch() {
        this.mode = this.STOP;
        this.addTime = 0;
        this.millisec = this.sec100 = this.sec = this.min = this.hour = this.nowTime = this.startTime = 0;
        // 戻り値は分のため60で割る
        this.gmt = DateFactory.newDate().getTimezoneOffset() / 60;
        this.diff = new Date(this.nowTime - this.startTime);

        const now = DateFactory.newDate();
        this.stopWatchDateTime = new StopWatchDateTime(this.hour, this.min, this.sec);
        this.stopWatchDateTime.startDateTime = now;
        this.stopWatchDateTime.endDateTime = now
    }

    public start() {
        if (this.mode === this.STOP) {
            this.mode = this.RUN;
            // スタート時刻を設定（ストップウォッチが進んでいれば加算）
            const now = DateFactory.newDate();
            this.startTime = now.getTime();
            if (this.theFirstStartDate === undefined) {
                this.theFirstStartDate = now;
                this.stopWatchDateTime.startDateTime = now;
            }
        }
    }

    public stop() {
        if (this.mode === this.RUN) {
            this.mode = this.STOP;
            this.calc();
        }
    }

    private calc() {
        const now = DateFactory.newDate();
        this.stopDate = now;
        this.nowTime = now.getTime();

        const diff = DateFactory.newDate();
        diff.setTime(this.nowTime - this.startTime);

        this.diff.setTime(this.diff.getTime() + diff.getTime());

        // ミリ秒、100分の1秒、秒、分、時を設定
        this.millisec = this.diff.getMilliseconds();
        this.sec100 = Math.floor(this.millisec / 10);
        this.sec = this.diff.getSeconds();
        this.min = this.diff.getMinutes();
        this.hour = this.diff.getHours() + this.gmt;
    }

    public get getDateTime(): StopWatchDateTime {
        return this.stopWatchDateTime;
    }

    public update() {
        this.stopWatchDateTime.hour = this.hour;
        this.stopWatchDateTime.min = this.min;
        this.stopWatchDateTime.sec = this.sec;
        this.stopWatchDateTime.endDateTime = DateFactory.newDate();
        if (this.stopDate !== undefined)
            this.stopWatchDateTime.endDateTime = this.stopDate;
    }
}