
























import {Component, Prop, Vue} from "vue-property-decorator";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";

@Component({
  components: {KawaiiButton, HoverLinkCard}
})
export default class MentalCheckSelection extends Vue {
  name = "MentalCheckSelection";

  @Prop({default: ''}) redirectPath!: string;

  canAnyMentalCheck = false;
  mentalChecks = [];
  redirect = '/mypage/mentalcheck';

  beforeMount() {

    const redirect = this.$route.params.redirect;
    if (redirect) {
      this.redirect = redirect;
    }

    if (this.redirectPath !== '') {
      this.redirect = this.redirectPath;
    }

    httpResource.get(`/mental-check/can-any-mentalcheck`)
        .then((response: any) => {
          this.canAnyMentalCheck = response.data;

          if (this.canAnyMentalCheck) {
            httpResource.get(`/mental-check/can-do-mentalchecks`)
                .then((response: any) => {
                  this.mentalChecks = response.data;
                });
          } else {
            this.$router.push(this.redirect);
          }
        });
  }
}
