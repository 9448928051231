





















import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import DefaultVue from "vue";
import CustomerSearchTable from "@/components/infrastructures/molecules/CustomerSearchTable.vue";
import {AuthGetterTypes} from "@/store/auth/types";
import {PurchaseGetterTypes, PurchaseMutationTypes} from '@/store/product/purchase/types';
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

export class CustomerNotRegisteringSearchAction implements SearchAction {

  productId: string;

  constructor(productId: string) {
    this.productId = productId;
  }

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(
        `payment/customers-not-purchased?condition=${condition}&productId=${this.productId}`);
    return response.data;
  }
}

@Component({
  components: {PrimaryBtn, CustomerSearchTable}
})
export default class UserSelectStep extends Step {
  name = "UserSelectStep";
  selectedCustomers = [];
  selectedForMyself = false;
  searchCustomerAction: any = null;
  isMePurchased = true;

  beforeMount() {
    const productId = this.$store.getters[PurchaseGetterTypes.getProductId]
    const userId = this.$store.getters[AuthGetterTypes.getCurrentUser].userId
    this.searchCustomerAction = new CustomerNotRegisteringSearchAction(productId);
    httpResource.get(`payment/is-purchased?userId=${userId}&productId=${productId}`).then((response: any) => {
      this.isMePurchased = response.data
    })
  }

  public getComponentName(): string {
    return this.name;
  }

  public getStepTitle(): string {
    return "ご使用者様の選択";
  }

  @Watch('selectedCustomers')
  changeSelectedCustomers() {
    // deep copy
    const selectedUsers = JSON.parse(JSON.stringify(this.selectedCustomers)).map((user: any) => {
      return user.id
    })

    if (this.selectedForMyself) {
      const currentUser = this.$store.getters[AuthGetterTypes.getCurrentUser]
      selectedUsers.push(currentUser.userId)
    }

    this.$store.commit(PurchaseMutationTypes.setUsers, selectedUsers)
    this.checkComplete()
  }

  validate(): boolean {
    return this.$store.getters[PurchaseGetterTypes.getUsers].length > 0;
  }
}

DefaultVue.component(UserSelectStep.name, UserSelectStep);
