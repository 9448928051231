


























import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import DefaultVue from "vue";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";

@Component
export default class SearchTable extends Vue {

  name = "SearchTable.vue";
  // args properties
  @Prop() private value!: [];
  @Prop() btnText!: string;
  @Prop() placeholder!: string;
  @Prop() headers!: { [text: string]: string };
  @Prop() itemKey!: string;
  @Prop() searchAction!: SearchAction;
  @Prop({default: false}) hideSearchFunction: boolean;
  @Prop() singleSelect: boolean;
  @Prop({default: true}) showSelect: boolean;
  @Prop({default: true}) hideDefaultFooter: boolean;

  // pagination properties
  @Prop({default: 5}) itemsPerPage: number;
  @Prop({default: false}) enablePagination: boolean;
  @Prop({default: false}) disableSort: boolean;

  // page
  page: any = 0;

  // local properties
  private condition = ''
  private items: {}[] = [];
  private pageCount = 1;
  private loading = true;

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  created() {
    // TODO: ページング対応
    this.search();
  }

  mounted() {
    this.search();
  }

  public search() {
    this.loading = true

    this.searchAction.action(this.condition).then((items: [{}]) => {
      // keyが一致していた場合updateしない
      if (items.length !== this.items.length) {
        this.items = items;
        return;
      }
      const thisItemKeys = this.items.map((item: any) => {
        return item[this.itemKey]
      });
      for (const item of items) {
        if (!thisItemKeys.includes((item as any)[this.itemKey])) {
          this.items = items;
          break;
        }
      }
    }).finally(() => {
      this.loading = false
    });
  }

  @Watch("condition")
  private searchByNewCondition() {
    this.searchAction.action(this.condition).then((items: [{}]) => {
      this.items = items;
    })
  }

  @Watch("pageCount")
  private fetchPageItems() {

    if (!this.enablePagination) {
      return;
    }
    // エミットで全体件数を取得

    // 全体件数、ページ数、検索条件を使用して検索

    this.items = []; // 検索結果
  }
}

// todo これ main.ts に置く
DefaultVue.component(SearchTable.name, SearchTable);
