





















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {StripeElementCard} from "@vue-stripe/vue-stripe";
import CreditCard from "@/components/infrastructures/atoms/CreditCard.vue";
import {AuthActionTypes} from "@/store/auth/types";

@Component({
  components: {StripeElementCard, CreditCard}
})
export default class OrderDetails extends Vue {

  public name = "OrderDetails";
  @Prop({default: []}) items: [];
  @Prop({default: true}) showNextBillingDate: boolean;
  headers: {}[] = []
  loading = true

  async created() {
    this.headers = [
      {text: "商品名", value: "productName"},
      {text: "数量", value: "quantity"},
      {text: "単価", value: "unitPriceWithYen"},
    ]

    const hasPicRole = await this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_PERSON_IN_CHARGE"})
    const hasAdminRole = await this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_ADMIN"})

    if (hasPicRole || hasAdminRole) {
      this.headers = [
        {text: "商品名", value: "productName"},
        {text: "対象", value: "role"},
        {text: "数量", value: "quantity"},
        {text: "単価", value: "unitPriceWithYen"}
      ]
    }
  }

  mounted() {
    if (this.showNextBillingDate) {
      this.headers.push({text: "次回の請求日", value: "nextBillDate"});
    }
  }

  @Watch("items")
  getReady() {
    this.loading = this.items.length == 0
  }

  calcTotal() {
    let total = 0;
    for (const item of this.items) {
      total += Number((item as any).quantity) * Number((item as any).unitPrice)
    }
    return Number(total).toLocaleString();
  }

  preformattedItems() {
    const items = this.items.slice()
    return items.map((item: any) => {
      item["unitPriceWithYen"] = "\xA5" + Number(item.unitPrice).toLocaleString();
      return item
    })
  }

}
