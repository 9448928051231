import {User} from "@/components/domains/authorization/user";
import {Agency} from "@/components/domains/authorization/agency";

export interface AccountTransferState {
    user: User;
    targetAgency: Agency;
    targetRoles: string[];
}

export enum AccountTransferGetterTypes {
    getUser = 'getUser',
    getTargetAgency = 'getTargetAgency',
    getTargetRoles = 'getTargetRoles'
}

export enum AccountTransferMutationTypes {
    setUser = 'setUser',
    setTargetAgency = 'setTargetAgency',
    setTargetRoles = 'setTargetRoles'
}
