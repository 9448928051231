

























import {Component, Prop, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import FieldData from "@/components/infrastructures/functions/fieldData";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class MentalCheck extends Vue {

  name = "MentalCheck";

  @Prop({default: ''}) id!: string;
  @Prop({default: false}) hideBackBtn!: boolean;
  @Prop({default: true}) afterSendPush!: boolean;

  mentalCheckId: string = this.$route.params.id;

  // local
  title = "";
  fields = [];

  // dialog
  dialog = false;
  dialogMsg = "";

  beforeMount() {

    if (!this.mentalCheckId) {
      this.mentalCheckId = this.id;
    }

    httpResource.get(`/mental-check/get?mentalCheckId=${this.mentalCheckId}`)
        .then((response: any) => {
          const mentalCheck = response.data;
          this.title = mentalCheck.title;
          this.createFieldsFromItem(mentalCheck);
        });
  }

  createFieldsFromItem(mentalCheck: any) {

    this.fields = mentalCheck.items
        .map((i: any) => {
          i.choices.sort((c1: any, c2: any) => {
            return c1.ansValue - c2.ansValue;
          });
          return i;
        })
        .sort((i1: any, i2: any) => {
          return i1.questionNo - i2.questionNo;
        })
        .map((i: any) => {

          const fieldData = new FieldData()
              .withId(i.id)
              .withType("select")
              .withRequires(i.requires)
              .withSelection(i.choices
                  .map((choice: any) => {
                    return {text: choice.display, value: choice.id}
                  }));

          return {field: fieldData, item: i}
        });
  }

  validate(): boolean {

    // validate
    const filteredFields = this.fields.filter((f: any) => {
      if (f.field.value === "") {
        f.field.withError(true);
        f.field.withErrorMessage("選択してください。");
        return false;
      }
      return true;
    });

    if (this.fields.length !== filteredFields.length) {
      return false;
    }

    return true;
  }

  send() {

    if (!this.validate()) {
      return;
    }

    const answers = this.fields
        .map((f: any) => {
          return {itemId: f.field.id, choiceId: f.field.value}
        });

    const request = {
      mentalCheckId: this.mentalCheckId,
      answers: answers
    };

    this.dialog = false;
    this.dialogMsg = "";

    // send
    httpResource.post("/mental-check/answer", request)
        .then(() => {
          this.dialogMsg = "送信完了しました。"
        })
        .catch((error: any) => {
          this.dialogMsg = error.response.data.message;
        })
        .finally(() => {
          this.dialog = true;
        });
  }

  decide() {
    this.dialog = false;
    this.$emit("afterSending");
    if (this.afterSendPush) {
      this.$router.push("/mypage/mentalcheck");
    }
  }
}
