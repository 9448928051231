import {ActionTree} from "vuex";
import {RootState} from "@/store/type";
import {CalteActionTypes, CalteGetterTypes, CalteState} from "@/store/calte/types";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

const actions: ActionTree<CalteState, RootState> = {
    [CalteActionTypes.registerCalteRecord]: async ({getters}) => {
        const calteRecord = getters[CalteGetterTypes.getCalteRecord];
        const aiEstheFaceDetectResult = getters[CalteGetterTypes.getAIEstheFaceDetectResult];
        const request = {
            aiEstheDetectFaceResultId: aiEstheFaceDetectResult.id,
            calteRecord: calteRecord
        }
        return httpResource.post("common/calte/register-record/", request);
    },
    [CalteActionTypes.registerCalteFormat]: async ({getters}) => {
        const calteFormat = getters[CalteGetterTypes.getCalteFormat];
        const selectedUser = getters[CalteGetterTypes.getSelectedCustomer];
        const request = {
            userId: selectedUser.id,
            calteFormat: calteFormat
        }
        return httpResource.post("pic/calte/register-format/", request)
    },
    [CalteActionTypes.registerCalteTemplate]: async ({getters}) => {
        const calteFormat = getters[CalteGetterTypes.getCalteFormat];
        const request = {
            templateName: calteFormat.title + 'テンプレート',
            calteFormat: calteFormat
        }
        return httpResource.post("pic/calte/register-template/", request)
    },
    [CalteActionTypes.searchCalteFormat]: async ({dispatch}, {userId}) => {
        // const request = {
        //     userId: userId,
        //     count: 10,
        //     status: null
        // }
        return httpResource.get(`pic/calte/get-format?userId=${userId}`);
    }
}

export default actions;
