import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {PageSizeMutationTypes} from "@/store/pagesize/types";
import store from '@/store';

export default class PageSizeService {

    public static async save(scale: number) {
        return httpResource.post(`page-size/set-scale?scale=${scale}`);
    }

    public static async fetchScale() {
        httpResource.get(`page-size/scale`)
            .then((response: any) => {
                const ratio = response.data;
                store.commit(PageSizeMutationTypes.setScale, ratio);
                document.body.style.zoom = String(ratio);
            });
    }
}