














import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class IconButton extends Vue {
  name = "IconButton"

  @Prop({})
  iconName!: string;

  @Prop({})
  annotation!: string;

  @Prop({})
  click: Function | undefined;
}
