









import {Component} from "vue-property-decorator";
import LoggedInHeader from "@/components/templates/loggedin/LoggedInHeader.vue";

@Component
export default class ManagementHeader extends LoggedInHeader {
  name = "ManagementHeader";
}
