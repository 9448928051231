export default class IndexedAudio {

    public index = 0;
    public id = '';
    public src = '';
    public audioData: any = '';
    public selected = false;

    public stop() {
        this.pause().then(() => {
            this.reset();
        });
    }

    public reset() {
        if (this.audioData != '') {
            this.audioData.currentTime = 0;
        }
    }

    public async pause() {
        if (this.audioData != '') {
            return await this.audioData.pause();
        }
    }

    public async start() {
        if (this.audioData != '') {
            return await this.audioData.play();
        }
    }
}