










import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class KawaiiButton extends Vue {

  name = "KawaiiButton";
  @Prop({default: 'secondary'}) color: string;
  @Prop({default: false}) disabled: boolean;
  @Prop({default: false}) outlined: boolean;
  @Prop({default: ''}) to: string;

  clickAction() {
    this.$emit('click')
  }

  get getClass(): string {
    if (this.color === 'grey') {
      return "ma-1 lighten-4";
    }
    return "ma-1";
  }
}
