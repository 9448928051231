
































































































import {Component, Vue} from "vue-property-decorator";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import LoginForm from "@/components/domains/account/login/LoginForm.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import authService from "@/components/domains/authorization/authService";
import KawaiiSoundPlayer from "@/components/infrastructures/molecules/KawaiiSoundPlayer.vue";

@Component({
  components: {KawaiiSoundPlayer, SubBtn, PrimaryBtn, LoginForm, KawaiiButton, StopWatchSoundPlayer: DMVPlayer}
})
export default class HomeTop extends Vue {
  name = "HomeTop";
  soundId = '';
  soundSrc = '';
  soundTitle = '';
  soundGenre = '';
  place = '';
  tags = [];
  playing = false;
  playingDateTime = new StopWatchDateTime();

  device = navigator.userAgent.toLowerCase();

  beforeMount() {

    httpResource.get('/soundesthe/free-sound')
        .then((response: any) => {
          const data = response.data;
          this.soundId = data.id;
          this.soundSrc = data.soundSrc;
          this.soundTitle = data.title;
          this.soundGenre = data.genre;
          this.place = data.place;
          this.tags = data.tags;
        })
        .catch(() => {
          alert('振動選択に失敗しました。');
        });
  }

  startStopListener(playing: boolean) {
    this.playing = playing;
  }

  receiveDateTimeOnStopped(dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
  }

  finish() {

    // const request = {
    //   planCode: Plan.RANDOM.code,
    //   vibrationId: this.soundId,
    //   startDateTime: this.playingDateTime.formattedStartDateTime,
    //   endDateTime: this.playingDateTime.formattedEndDateTime,
    //   totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    // };
    //
    // httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/soundesthe/");
  }

  logout() {
    authService.logout()
        .finally(() => {
          this.$router.push("/");
        });
  }

  get isSmartphone() {
    return /ipad|iphone|ipod|android/.test(this.device);
  }

  get getSoundSrc() {
    return this.soundSrc;
  }

  get isLoggedIn(): boolean {
    return authService.isLoggedIn();
  }
}
