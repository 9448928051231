export class Plan {

    private static readonly _values: Plan[] = []

    static readonly RANDOM = new Plan("01", "おまかせ");
    static readonly FEELING = new Plan("02", "気分で選ぶ");
    static readonly FACE_ANALYZE = new Plan("03", "顔診断エステ");
    static readonly PURPOSE = new Plan("04", "目的で選ぶ");
    static readonly SOUND_THERAPY = new Plan("05", "音楽療法");
    static readonly ENTERPRISE_RANDOM = new Plan("90", "エンタープライズおまかせ");

    private constructor(
        readonly code: string,
        readonly name: string) {
        Plan._values.push(this);
    }

    static fromCode(code: string): Plan | undefined {
        return Plan._values.find(x => x.code === code);
    }

    static codes(): Plan[] {
        return Plan._values
    }
}