import {Module} from "vuex";
import {RootState} from "@/store/type";
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {PurchaseState} from "@/store/product/purchase/types";

const state: PurchaseState = {
    productId: "",
    creditCardId: "",
    addressId: "",
    users: []
}

export const purchase: Module<PurchaseState, RootState> = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
