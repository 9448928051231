





















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class KawaiiSoundPlayer extends Vue {

  debug = false;

  el = "#audio"
  name = "KawaiiSoundPlayer"

  playing = false;

  @Prop() soundSrc!: string;
  @Prop({default: "0"}) id!: string;
  @Prop({default: false}) autoPlay!: boolean;
  @Prop({default: false}) loop!: boolean;
  @Prop({default: false}) normal!: boolean;
  @Prop({default: true}) finishedActionOnEnded!: boolean;
  @Prop({default: 0}) seeked!: number;

  @Prop({
    default: () => function () {
      console.debug("Start playing audio.")
    }
  }) playAction!: any;

  @Prop({
    default: () => function () {
      console.debug("Pause audio.")
    }
  }) pauseAction!: any;

  @Prop({
    default: () => function () {
      console.debug("Finish playing audio.")
    }
  }) finishedAction!: any;

  @Prop({
    default: () => function () {
      console.debug("Ended playing audio.")
    }
  }) endedAction!: any;

  @Prop({
    default: () => function () {
      console.debug("Time updated.")
    }
  }) timeUpdate!: any;

  @Prop({
    default: () => function () {
      console.debug("Start or Stop")
    }
  }) startStopListener!: any;

  audio: any = undefined;
  currentSeconds = 0;
  durationSeconds = 0;
  innerLoop = false;
  loaded = false;
  previousVolume = 35;
  showVolume = false;
  volume = 100;
  onseeked = 0;

  device = navigator.userAgent.toLowerCase();

  // iPhone, Android または Chrome 以外の場合
  smartphone = !/chrome/.test(this.device) || /ipad|iphone|ipod/.test(this.device);

  started = false;

  convertTimeHHMMSS = (val: any) => {
    const hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
    return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
  };

  mounted() {
    this.initAudio();
    this.innerLoop = this.loop;
    this.onseeked = this.seeked;
  }

  initAudio() {
    this.audio = this.$el.querySelectorAll('audio')[0];
    this.audio.addEventListener('timeupdate', this.update);

    if (this.smartphone) {
      this.audio.onloadedmetadata = function () {
        this.loaded = true;
      };
    }
    this.audio.addEventListener('loadeddata', this.load);
    this.audio.addEventListener('pause', () => {
      this.playing = false;
      this.pauseAction(this);
    });
    this.audio.addEventListener('ended', () => {
      if (this.finishedActionOnEnded) {
        this.finishedAction(this);
      }
      this.endedAction(this);
    })
    this.audio.addEventListener('play', () => {
      this.playing = true;
      this.started = true;
      this.playAction(this);
    });
  }

  download() {
    this.stop();
    alert("ダウンロードはできません。")
    // window.open(this.soundSrc, 'download');
  }

  load() {
    if (this.audio.readyState >= 2) {
      this.loaded = true;
      this.durationSeconds = parseInt(this.audio.duration);

      if (this.autoPlay) {
        this.audio.play();
      }
      return this.playing = this.autoPlay;
    }

    throw new Error('Failed to load sound file.');
  }

  playAudioWithSoundSrc(soundSrc: string) {
    this.soundSrc = soundSrc;
    this.audio.play();
  }

  playAudio() {
    this.audio.play();
  }

  pauseAudio() {
    this.audio.pause();
  }

  mute() {
    if (this.muted) {
      return this.volume = this.previousVolume;
    }

    this.previousVolume = this.volume;
    this.volume = 0;
  }

  seek(e: any) {
    if (!this.playing || e.target.tagName === 'SPAN') {
      return;
    }
    const el = e.target.getBoundingClientRect();
    const seekPos = (e.clientX - el.left) / el.width;

    this.audio.currentTime = parseInt(String(this.audio.duration * seekPos));
  }

  stop() {
    this.playing = false;
    this.audio.currentTime = 0;
  }

  update(e: any) {
    this.currentSeconds = parseInt(this.audio.currentTime);
    this.timeUpdate(e, this.currentSeconds);
  }

  get currentTime() {
    return this.convertTimeHHMMSS(this.currentSeconds);
  }

  get durationTime() {
    return this.convertTimeHHMMSS(this.durationSeconds);
  }

  get percentComplete() {
    return parseInt(String(this.currentSeconds / this.durationSeconds * 100));
  }

  get muted() {
    return this.volume / 100 === 0;
  }

  isFinished(): boolean {
    return this.started && !this.playing && this.percentComplete === 100;
  }

  @Watch("playing")
  stopOrPlay() {
    this.startStopListener(this.playing);
    if (this.isFinished()) {
      // this.finishedAction()
      this.started = false;
    }
  }

  @Watch("loop")
  setLoop() {
    if (this.autoPlay) {
      return;
    }
    this.audio.loop = this.loop;
  }

  @Watch("audio.loop")
  blockLoop() {
    if (this.autoPlay) {
      return;
    }
    this.audio.loop = this.loop;
  }
}
