import { render, staticRenderFns } from "./BigRadio.vue?vue&type=template&id=6b0f41a3&scoped=true&"
import script from "./BigRadio.vue?vue&type=script&lang=ts&"
export * from "./BigRadio.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b0f41a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio})
