











import {Component, Vue} from "vue-property-decorator";
import EasilyLoginAgreement from "@/components/domains/settings/easilylogin/EasilyLoginAgreement.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component({
  components: {EasilyLoginAgreement}
})
export default class EasilyLoginSetting extends Vue {
  name = "EasilyLoginSetting";

  beforeMount() {
    this.checkAgreed();
  }

  checkAgreed() {
    httpResource.get('/easily-login/agreement')
        .then((response: any) => {
          const agree = response.data;
          if (!agree) {
            this.$router.push('/mypage/settings/easily-login/agreement');
          } else if (this.$router.currentRoute.path !== '/mypage/settings/easily-login/issue-url') {
            this.$router.push('/mypage/settings/easily-login/issue-url');
          }
        });
  }
}
