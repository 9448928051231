












import {Component, Vue} from "vue-property-decorator";
import CustomerRegisterForm from "@/components/domains/account/register/CustomerRegisterForm.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";

@Component({
  components: {DefaultTemplate, RegisterForm: CustomerRegisterForm, GeneralField}
})
export default class CustomerRegisterPage extends Vue {
  name: "CustomerRegisterPage"
  agency = this.$route.params.agency === undefined ? '' : this.$route.params.agency;
  picId = this.$route.params.picId === undefined ? '' : this.$route.params.picId;
}
