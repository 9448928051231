


















import {Component, Watch} from "vue-property-decorator";
import DefaultVue from "vue";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AIEstheActionTypes, AIEstheGetterTypes, AIEstheMutationTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import BigRadio from "@/components/infrastructures/atoms/designed/BigRadio.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";

@Component({
  components: {BackBtn, BigRadio, KawaiiButton, VerticalSlotContents}
})
export default class SelectCourseStep extends Step {
  name = "SelectCourseStep";
  selected = 0;

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    this.$store.dispatch(AIEstheActionTypes.getCurrentStep).then(() => {
      const after = this.$store.getters[AIEstheGetterTypes.isAfter];
      if (after) {
        this.next();
      }
    });
    // this.selected = this.$store.getters[CameraGetterTypes.getCourse];
  }

  getStepTitle(): string {
    return 'コース選択';
  }

  @Watch("selected")
  watchSelected() {
    this.$store.commit(AIEstheMutationTypes.setCourse, this.selected);
    if (this.selected != 1 && this.selected != 2) {
      this.doCancel();
    } else {
      this.doComplete();
    }
    if (this.isCompleted()) {
      this.nextStep();
    }
  }

  nextStep() {
    this.next();
  }
}

DefaultVue.component(SelectCourseStep.name, SelectCourseStep);
