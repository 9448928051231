import {RootState} from "@/store/type";
import {Module} from "vuex";
import {PageSizeState} from "@/store/pagesize/types";
import getters from "@/store/pagesize/getters";
import mutations from "@/store/pagesize/mutations";

const state: PageSizeState = {
    scale: 1
}

export const pagesize: Module<PageSizeState, RootState> = {
    namespaced: false,
    state,
    getters,
    mutations
}
