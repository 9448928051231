



































































import {Component, Vue} from 'vue-property-decorator';
import ResponsiveYouTube from "@/components/infrastructures/atoms/ResponsiveYouTube.vue";
import SoundEstheRandomPlan from "@/components/domains/esthe/soundesthe/plans/SoundEstheRandomPlan.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import MenuPanel from "@/components/domains/account/mypage/MenuPanel.vue";
import ProvidingDataAgreementDialog from "@/components/domains/agreement/ProvidingDataAgreementDialog.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import Overview from "@/components/domains/home/contents/Overview.vue";
import HowToUse from "@/components/domains/home/contents/HowToUse.vue";
import HomeTop from "@/components/domains/home/contents/HomeTop.vue";
import WhatsDMV from "@/components/domains/home/contents/WhatsDMV.vue";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import IntroPapers from "@/components/domains/home/contents/IntroPapers.vue";
import Awards from "@/components/domains/home/contents/Awards.vue";
import Contact from "@/components/domains/home/contents/Contact.vue";
import MentalCheckInductionHoverLink from "@/components/domains/mentalcheck/MentalCheckInductionHoverLink.vue";
import MentalCheckInductionCard from "@/components/domains/mentalcheck/MentalCheckInductionCard.vue";
import Plans from "@/components/domains/home/contents/Plans.vue";
import AuthService from "@/components/domains/authorization/authService";

@Component({
  components: {
    Plans,
    MentalCheckInductionCard,
    MentalCheckInductionHoverLink,
    Contact,
    Awards,
    IntroPapers,
    HomeTemplate,
    WhatsDMV,
    HomeTop,
    HowToUse,
    Overview,
    DefaultTemplate,
    MenuPanel, KawaiiButton, StopWatchSoundPlayer: DMVPlayer, SoundEstheRandomPlan, ResponsiveYouTube,
    ProvidingDataAgreement: ProvidingDataAgreementDialog
  }
})
export default class HomePage extends Vue {

  name = 'Home';

  currentPage = 0;
  pageCount = 0;

  mounted() {
    // if (this.$vuetify.breakpoint.smAndDown) {
    //   if (this.isLoggedIn) {
    //     router.push("/menu");
    //   } else {
    //     router.push("/login");
    //   }
    // }
  }

  get isLoggedIn(): boolean {
    return AuthService.isLoggedIn();
  }

  get isEnterprise(): boolean {
    return AuthService.isEnterprise();
  }

  get isAdmin(): boolean {
    return AuthService.isAdmin();
  }

  links = [
    {'name': 'トップ', 'link': '#top'},
    {'name': 'サービス', 'link': '#services'},
    {'name': '使い方と効果', 'link': '#how-to-use'},
    {'name': '深層振動とは', 'link': '#whats-dmv'},
    {'name': '論文紹介', 'link': '#intro-papers'},
    {'name': '掲載メディア', 'link': '#awards'},
    {'name': 'プレミアムプラン', 'link': '#plans'},
  ];
}
