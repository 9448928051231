


































import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AIEstheGetterTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DefaultVue from "vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import PlayWithSoundEsthe from "@/components/domains/esthe/soundesthe/PlayWithSoundEsthe.vue";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";

/**
 * 気分でコース用
 */
@Component({
  components: {
    SmartDMVPlayer,
    PlayWithSoundEsthe,
    DMVPlayer,
    PrimaryBtn, BackBtn, VerticalSlotContents, KawaiiButton
  }
})
export default class FeelingVibrationExperienceStep extends Step {

  name = "FeelingVibrationExperienceStep";
  soundId = '';
  soundSrc = '';
  playing = false;
  playingDateTime = new StopWatchDateTime();

  getStepTitle(): string {
    return "再生";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {

    const clusterId = this.$store.getters[AIEstheGetterTypes.getCourse];

    if (clusterId == 0) {
      this.back();
    }

    httpResource.get(`/aiesthe/cluster?clusterId=${clusterId}`)
    .then((response: any) => {
      this.setSoundId(response.data.id);
      this.setSoundSrc(response.data.soundSrc);
    })
    .catch((error: any) => {
      alert('振動選択に失敗しました。');
    });
  }

  setSoundId(soundId: string) {
    this.soundId = soundId;
  }

  setSoundSrc(soundSrc: string) {
    this.soundSrc = soundSrc;
  }

  receiveDateTimeOnStarted() {
    this.playing = true;
  }

  receiveDateTimeOnStopped(audio: IndexedAudio, dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
    this.playing = false;
  }

  finish() {

    const request = {
      dmvTypeCode: "01",
      planCode: Plan.FEELING.code,
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/aiesthe/");
  }
}

DefaultVue.component(FeelingVibrationExperienceStep.name, FeelingVibrationExperienceStep);
