import QuestionData from "@/components/infrastructures/functions/questionnaire/questionData";

export default class StressCheckQuestionHolder {

    private static questions: QuestionData[] = [
        "よく風邪をひくし、治りにくい",
        "頭痛が起こる",
        "肩が凝りやすい",
        "背中や腰が痛くなることがよくある",
        "後頭部がつまった感じで頭がスッキリしない",
        "能率が悪くなり、判断がつきにくい",
        "手足が冷たいことが多い",
        "手のひらや、脇の下に汗が出ることが多い",
        "手が震える",
        "体がほてる",
        "急に息苦しくなることがある",
        "動悸を打つことがある",
        "目がよく疲れる",
        "めまい、立ちくらみがある",
        "耳鳴りがすることがある",
        "音に敏感",
        "好きなものもあまり食べるきがしない",
        "食事をしても味がしない",
        "いつも食べ物が胃にもたれる気がする",
        "吐き気がする",
        "胃が痛い",
        "腹がはったり、下痢をしたりする",
        "口がかわいたり、口の中がねばねばしたりする",
        "この頃体重が減った",
        "何かするとすぐ疲れる",
        "寝付きが悪い",
        "夜中目がさめやすい",
        "夢を見ることが多い",
        "疲れが残り、朝気持ちよく起きれない",
        "いつも眠い",
        "仕事をする気がおこらない",
        "いつも仕事に追われている感じがある",
        "日曜日はぐったりしている",
        "家庭でもゆっくりくつろげない",
        "家族や友人と外出することはある",
        "人と会うとイライラしたり、腹が立ったりしやすい",
        "妻（夫）や家族にあたることが多い",
        "身だしなみを整えることが面倒になった",
        "自分が弱くなった感じ",
        "これまで楽しんでやれていたことが楽しめなくなった",
    ]
        .map(q => new QuestionData(q));
    // .map(q => {
    //     q.selection = QuestionnaireSelection.NOT_APPLICABLE;
    //     return q;
    // });

    public static getStressCheckQuestions(): QuestionData[] {
        return this.questions
    }
}