import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";

export interface AIEstheState {
    id: string;
    course: number;
    after: boolean;
    image: string;
    soundId: string;
    soundSrc: string;
    bodyPart: string;
    publish: boolean;
    dateTime: StopWatchDateTime;
    detectFaceResult: {};
}

export enum AIEstheGetterTypes {
    isAfter = 'isAfter',
    getCourse = 'getCourse',
    getCurrentImage = 'getCurrentImage',
    getId = 'getId',
    getSoundId = 'getSoundId',
    getSoundSrc = 'getSoundSrc',
    getBodyPart = 'getBodyPart',
    isPublish = 'isPublish',
    getDateTime = 'getDateTime',
    getDetectFaceResult = 'getDetectFaceResult'
}

export enum AIEstheActionTypes {
    sendImage = 'sendImage',
    sendImageAfter = 'sendImageAfter',
    getCurrentStep = 'getCurrentStep'
}

export enum AIEstheMutationTypes {
    setImage = 'setImage',
    setAfter = 'setAfter',
    setCourse = 'setCourse',
    setId = 'setId',
    setSoundId = 'setSoundId',
    setSoundSrc = 'setSoundSrc',
    setBodyPart = 'setBodyPart',
    setPublish = 'setPublish',
    setDateTime = 'setDateTime',
    setDetectFaceResult = 'setDetectFaceResult'
}
