import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class ActivityService {

    public static async getActivityNotice(): Promise<{
        message: string;
        dullnessElimination: string;
        rejuvenationAdvice: string;
        attractivenessUp: string;
    }> {

        return (await httpResource.get('/v1/activity/notice')).data;
    }
}