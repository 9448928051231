

























import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import NatureSoundCard from "@/components/domains/esthe/soundesthe/plans/categories/naturesound/NatureSoundCard.vue";
import KanadeCard from "@/components/domains/esthe/soundesthe/plans/categories/kanade/KanadeCard.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";

@Component({
  components: {BackBtn, MyPageContentTemplate, KanadeCard, NatureSoundCard, KawaiiButton}
})
export default class SoundEstheCategoryPlan extends Vue {
  name = "SoundEstheCategoryPlan";
}
