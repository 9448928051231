













import {Component, Prop, Vue} from "vue-property-decorator";
import SearchDialog, {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import DefaultVue from "vue";

class CustomerSearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(`pic/calte/customers?condition=${condition}`);
    return response.data.users;
  }
}

@Component(
    {
      components: {
        SearchDialog
      }
    }
)
export default class ChooseCustomerDialog extends Vue {

  name = "ChooseCustomerDialog";
  selectedCustomer!: any;
  @Prop() onDecideAction: Function;
  customers: any[] = [];
  headers = [
    {text: "顧客ID", value: "id"},
    {text: "顧客名", value: "name"},
    {text: "メールアドレス", value: "email"}
  ];
  searchCustomerAction: SearchAction = new CustomerSearchAction();

  onDecide(customer: any) {
    this.selectedCustomer = customer;
    this.onDecideAction(customer);
  }
}

DefaultVue.component(ChooseCustomerDialog.name, ChooseCustomerDialog);

