








































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import SupportService from "@/components/domains/support/supportService";
import Chat from "@/components/domains/support/Chat.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import {AuthActionTypes} from "@/store/auth/types";

@Component({
  components: {PrimaryBtn, Chat, KawaiiDialog}
})
export default class InquiryList extends Vue {
  name = "InquiryList";
  @Prop() inquiries: any[];

  inquiry: any = {};
  chats: any = {};

  // dialog
  dialog = false;

  //message
  message = '';

  intervalId = 0;

  isAdmin = false;

  sending = false;

  beforeMount() {
    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_ADMIN"})
        .then((hasRole: boolean) => {
          this.isAdmin = hasRole;
        });
  }

  beforeDestroy() {
    clearInterval(this.intervalId);
  }

  chat() {
    this.sending = true;
    SupportService.chat(this.inquiry.id, this.message)
        .then(data => {
          this.chats.push(data);
        })
        .catch(() => {
          alert('チャットの送信に失敗しました。')
        })
        .finally(() => {
          this.message = '';
          this.sending = false;
        });
  }

  fetchChats(inquiry: any) {

    SupportService.fetchChats(inquiry.id)
        .then(data => {
          this.inquiry = inquiry;
          this.chats = data;
        })
        .catch(() => {
          alert('チャットの取得に失敗しました。');
        });
  }

  showInquiry(inquiry: any) {

    clearInterval(this.intervalId);
    this.intervalId =
        setInterval(() => this.fetchChats(inquiry), 3000);

    SupportService.read(inquiry.id).then(() => {
      this.fetchChats(inquiry);
      this.dialog = true;
    });
  }

  @Watch('dialog')
  changedDialog() {
    if (!this.dialog) {
      clearInterval(this.intervalId);
    }
  }
}
