































































































import {Component, Vue} from "vue-property-decorator";
import HpSmallText from "@/components/infrastructures/atoms/text/HpSmallText.vue";
import Copyright from "@/components/infrastructures/atoms/Copyright.vue";

@Component({
  components: {Copyright, HpSmallText}
})
export default class HomeFooter extends Vue {
  name = "HomeFooter"
  links = [
    {'name': 'トップ', 'link': '/#top'},
    {'name': 'サービス', 'link': '/#services'},
    {'name': '使い方と効果', 'link': '/#how-to-use'},
    {'name': '深層振動とは', 'link': '/#whats-dmv'},
    {'name': '論文紹介', 'link': '/#intro-papers'},
    {'name': '掲載メディア', 'link': '/#awards'},
  ];
}
