












































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import Canvas from "@/components/infrastructures/functions/canvas/canvas";
import CanvasBuilder from "@/components/infrastructures/functions/canvas/canvasBuilder";

// import adapter from 'webrtc-adapter';

const VIDEO_WIDTH = 720
const VIDEO_HEIGHT = 540
const DEBUG_DEFAULT = false
// const DEBUG_DEFAULT = true

@Component
export default class KawaiiCamera extends Vue {

  @Prop() imageData!: string;
  @Prop({default: DEBUG_DEFAULT}) debug: boolean;
  video: HTMLVideoElement;
  canvas: Canvas;
  taken = false;
  animationId = 0;
  cameraOn = true;

  debugInfo = '';

  mounted() {
    // handleDebug
    this.video = document.getElementById('video') as HTMLVideoElement
    this.canvas = CanvasBuilder
        .withCanvas(document.getElementById('canvas') as HTMLCanvasElement)
        .withWidth(VIDEO_WIDTH)
        .withHeight(VIDEO_HEIGHT)
        .build();
    try {
      this.stopStreamingVideo();
      this.startStreamingVideo();
    } catch {
      // NOP
    }
  }

  beforeDestroy() {
    this.stopStreamingVideo();
  }

  private capture() {
    this.pauseStreamingVideo()
    this.canvas.clearRect();
    this.canvas.drawImage(this.video);
    this.image = this.canvas.toDataURL()
    this.canvas.drawGrid(65);
  }

  private retake() {
    this.image = '';
    this.startStreamingVideo();
  }

  private toggleCameraOnOff() {
    if (this.cameraOn) {
      this.stopStreamingVideo();
    } else {
      this.startStreamingVideo();
    }
  }

  private select() {

    const file = ((this.$refs.input as unknown) as { files: File[] }).files[0]
    const selectedImage = new Image()
    const reader = new FileReader();

    reader.onload = (evt: ProgressEvent<FileReader>) => {
      selectedImage.onload = () => {

        const canvasWidth = this.canvas.getWidth;
        const canvasHeight = this.canvas.getHeight;

        // canvasより大きい場合収まるようにリサイズする
        if (selectedImage.width > canvasWidth) {
          selectedImage.height = selectedImage.height * (canvasWidth / selectedImage.width)
          selectedImage.width = canvasWidth
        }
        if (selectedImage.height > canvasHeight) {
          selectedImage.width = selectedImage.width * (canvasHeight / selectedImage.height)
          selectedImage.height = canvasHeight
        }

        this.pauseStreamingVideo();
        this.canvas.drawImageCenter(selectedImage);
        this.image = selectedImage.src
      }
      selectedImage.src = ((evt.target as unknown) as { result: string }).result;
    }
    reader.readAsDataURL(file)
  }

  private openFileSelector() {
    ((this.$refs.input as unknown) as { click: () => void }).click()
  }

  private startStreamingVideo() {
    const fileObj: HTMLDataElement = document.getElementById('file') as HTMLDataElement;
    fileObj.value = '';
    this.taken = false;

    const VIDEO_CONSTRAINTS = true

    // テスト用 START
    /*
    const VIDEO_CONSTRAINTS = {
      aspectRatio: {
        ideal: 16/9 // COMMON: 4/3 OR 16/9
      }
    }
    */
    /*
    if (new URL(window.location.href).searchParams.has('video')) {
      this.video.style.display = 'block';
      (document.querySelector('canvas') as any).style.display = 'none'
    }
    */
    // テスト用 END

    const constraints = {
      video: VIDEO_CONSTRAINTS
    }

    // 対策1
    const {resize} = this.canvas.syncVideoAndCanvasSize(this.video) // Returns { clear, resize }
    resize()
    // window.setTimeout(resize, 0)

    // 対策2
    // const { draw, clear } = this.canvas.containVideoInCanvas(this.video)

    this.video.play() // Programmatic play from synchronous user gesture.
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      this.video.srcObject = stream;
      this.video.play() // Programmatic play after MediaStream set.
      window.setTimeout(resize, 0) // 再生からのresize。

      const canvasUpdate = () => {
        this.canvas.drawImage(this.video);
        this.canvas.drawGrid(65);
        // 円描画
        // const height = this.canvas.getHeight;
        // const width = this.canvas.getWidth;
        // const y = height / 2.5;
        // const x = width / 2;
        // const baseWidth = (height > width ? height : width)
        // const radius = baseWidth * 0.27;
        //
        // const ctx = this.canvas.getCanvas2D();
        // ctx.beginPath();
        // ctx.arc(x, y, radius, 0, Math.PI * 2, true); // 外の円
        // ctx.moveTo(x + 35, 75);
        // ctx.stroke();

        if (this.video.paused) {
          console.warn('VIDEO IS PAUSED') // DEBUG. TURN OFF LATER!
        }

        if (!this.taken) {
          this.animationId = requestAnimationFrame(canvasUpdate);
        }
      }
      canvasUpdate();
    })

    this.cameraOn = true;
  }

  private pauseStreamingVideo() {
    this.taken = true;
    this.video.pause()
    cancelAnimationFrame(this.animationId);
    this.canvas.clearRect();
  }

  public stopStreamingVideo() {

    if (this.video.srcObject) {

      (this.video.srcObject as MediaStream).getTracks().forEach(function (track: MediaStreamTrack) {
        track.stop();
      });

      this.video.srcObject = null;
    }

    cancelAnimationFrame(this.animationId);
    this.canvas.clearRect();

    this.cameraOn = false;
  }

  @Emit()
  public input(image: string) {
    /* tslint:disable:no-empty */
  }

  private get image(): string {
    return this.imageData
  }

  private set image(image: string) {
    this.input(image)
  }
}
