












import {Component, Prop, Vue} from "vue-property-decorator";
import {StripeElementCard} from "@vue-stripe/vue-stripe";

@Component({
  components: {StripeElementCard}
})
export default class CreditCard extends Vue {

  public name = "CreditCard";
  @Prop({default: ''}) pulishableKey: string;
  token = null
  loading = false

  submit() {
    (this.$refs.elementRef as any).submit();
  }

  elementChange(condition: any) {
    if (condition.complete) {
      // nop
    }
  }

}
