






























import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import ProductLineups from "@/components/domains/products/ProductLineups.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";

@Component({
  components: {SubBtn, MyPageContentTemplate, ProductLineups, KawaiiDialog}
})
export default class ProductLineupDialog extends Vue {
  name = "ProductLineupDialog";

  @VModel() dialog: boolean;
  @Prop() products!: any[];
  @Prop() funcName: string;

  closeDialog() {
    this.dialog = false;
    this.$emit("close");
  }
}
