




































import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import {PageSizeGetterTypes, PageSizeMutationTypes} from "@/store/pagesize/types";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import PageSizeService from "@/components/domains/settings/pagesize/pageSizeService";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class PageSizeSetting extends Vue {
  name = "PageSizeSetting";
  zoomRatio = 1.0;

  dialog = false;
  message = "";

  mounted() {
    this.zoomRatio = this.$store.getters[PageSizeGetterTypes.getScale];
    this.zoom();
  }

  @Watch("zoomRatio")
  zoom() {
    if (this.zoomRatio < 0.70) {
      this.zoomRatio = 0.70;
      alert("これ以上の縮小はできません。")
      return;
    } else if (this.zoomRatio > 1.51) {
      this.zoomRatio = 1.50;
      alert("これ以上の拡大はできません。")
      return;
    }
    document.body.style.zoom = String(this.zoomRatio);
  }

  save() {
    PageSizeService.save(this.zoomRatio)
        .then((response: any) => {
          this.$store.commit(PageSizeMutationTypes.setScale, response.data);
          this.message = "登録完了しました。";
        })
        .catch(() => {
          this.message = "登録に失敗しました。"
        })
        .finally(() => {
          this.dialog = true;
        });
  }
}
