







import {Component, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PaymentMethod from "@/components/domains/settings/payment/PaymentMethod.vue";
import CreditCardForm from "@/components/domains/payment/creditcard/CreditCardForm.vue";

@Component({
  components: {CreditCardForm, PaymentMethod, MyPageContentTemplate}
})
export default class PaymentMethodPage extends Vue {
  name = "PaymentMethodPage";
}
