





































































import {Component, Prop, Vue} from "vue-property-decorator";
import authService from "@/components/domains/authorization/authService";
import WindowSize from "@/components/infrastructures/functions/window/windowSize";
import {AuthActionTypes} from "@/store/auth/types";
import router from "@/router";
import HomeFooter from "@/components/templates/home/HomeFooter.vue";
import LangSelection from "@/components/infrastructures/molecules/LangSelection.vue";

@Component({
  components: {LangSelection, HomeFooter}
})
export default class HomeSmartphoneTemplate extends Vue {
  name = "HomeSmartphoneTemplate";

  @Prop() links!: [];

  logout() {
    this.$store.dispatch(AuthActionTypes.logout).then(() => {
      router.push('/')
    });
  }

  get isLoggedIn() {
    return authService.isLoggedIn();
  }

  get isSmartphoneSize() {
    return WindowSize.isSmartphoneSize(this);
  }
}
