export default class CalteInputRecord {

    private id = '';
    private customerInput = false;

    constructor(private generalCalteItemId: string, private inputValue: string) {
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
    }

    public isCustomerInput(): boolean {
        return this.customerInput;
    }

    public setCustomerInput(customerInput: boolean) {
        this.customerInput = customerInput;
    }
}