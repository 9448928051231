









import {Component, Vue} from "vue-property-decorator";
import LoggedInHeader from "@/components/templates/loggedin/LoggedInHeader.vue";
import ManagementHeader from "@/components/templates/loggedin/management/ManagementHeader.vue";

@Component({
  components: {ManagementHeader, LoggedInHeader}
})
export default class ManagementTemplate extends Vue {
  name = "ManagementTemplate";
}
