































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DMVUploadTab from "@/components/domains/upload/dmvupload/DMVUploadTab.vue";
import DMVDeleteTab from "@/components/domains/upload/dmvupload/DMVDeleteTab.vue";
import EnterpriseUploadTab from "@/components/domains/upload/enterprise_upload/EnterpriseUploadTab.vue";
import EnterpriseDeleteTab from "@/components/domains/upload/enterprise_upload/EnterpriseDeleteTab.vue";

@Component({
  components: {EnterpriseDeleteTab, EnterpriseUploadTab, DMVDeleteTab, DMVUploadTab, KawaiiButton}
})
export default class EnterpriseUpload extends Vue {

  name = "EnterpriseUpload.vue";
  tab: any = '';
}
