









import {Component, Vue} from "vue-property-decorator";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import MentalCheckInductionHoverLink from "@/components/domains/mentalcheck/MentalCheckInductionHoverLink.vue";
import KanadeCard from "@/components/domains/esthe/soundesthe/plans/categories/kanade/KanadeCard.vue";
import NatureSoundCard from "@/components/domains/esthe/soundesthe/plans/categories/naturesound/NatureSoundCard.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import {DmvMutationTypes} from "@/store/dmv/types";

@Component({
  components: {MyPageContentTemplate, NatureSoundCard, KanadeCard, MentalCheckInductionHoverLink, HoverLinkCard}
})
export default class SoundEsthePlanSelectionPage extends Vue {
  name = "SoundEsthePlanSelectionPage";

  mounted() {
    this.$store.commit(DmvMutationTypes.setSelectedWave, []);
  }
}
