





















































import SoundDeleteTab from "../soundupload/SoundDeleteTab.vue";
import {Component} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class KanadeDeleteTab extends SoundDeleteTab {
  name = "KanadeDeleteTab";

  get headers() {
    return [
      // {text: "ID", value: "id"},
      {text: "タイトル", value: "title"},
      {text: "ジャンル", value: "genre"},
      {text: "タグ", value: "tags"},
      {text: "産地", value: "country"},
      {text: "奏者", value: "player"},
      {text: "目的", value: "purposeDetailId"},
      {text: "視聴", value: "path"},
      {text: "更新", value: "update"},
    ];
  }

  get fetchDMVList() {
    return httpResource.get("/upload/kanade-dmv-list")
        .then((response: any) => {
          this.vibrationList = response.data;
        });
  }
}
