






import {Component} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin"
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import SubscriptionForm from "@/components/domains/payment/shared/subscription/SubscriptionForm.vue";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";

@Component({
  components: {LoggedInTemplate, SubscriptionForm}
})
export default class PaymentPage extends AuthMixin {

  public name = "PaymentPage";
  productList: any[] = [];
  clicked = false

  beforeCreate() {
    httpResource.get(`api/product/fetch-all`).then((response) => {
      this.productList = response.data
    });
  }

}
