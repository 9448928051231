














import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class BonusCodeForm extends Vue {

  bonuscode = '';

  send() {
    httpResource.post('/v3/bonuscode/apply', {"code": this.bonuscode})
        .then((re) => {
          alert('特典が適用されました。')
          this.$router.push('/menu')
        })
        .catch(er => {
          alert(er.response.data.message);
        })
  }
}
