import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class SubscriptionService {

    public static async fetchAllSubscriptionProducts() {
        // TODO
        return (await httpResource.get('/v2/subscription/products')).data.filter((product: any) => {
            return product.displayName !== 'ヘルスケアコース'
                && product.displayName !== 'エグゼクティブ'
                && product.displayName !== '美容aiスタジオ通い放題'
                && product.displayName !== '美容aiスタジオ通い放題（顔リズムレンタル付き）'
        });
    }

    public static async updateCard(paymentId: string, cardId: string) {
        const data = {
            paymentHistoryId: paymentId,
            creditCardId: cardId
        }
        return (await httpResource.post('/v3/subscription/update-card', data)).data;
    }
}