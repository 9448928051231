













import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class NoticeBanner extends Vue {
  name = "NoticeBanner";
  @Prop({default: ''}) text!: string;
}
