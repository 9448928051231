import FieldData from "@/components/infrastructures/functions/fieldData";

export default class CalteFormatFieldData extends FieldData {

    customerInput = false;
    customerPublish = false;

    public withCustomerInput(customerInput: boolean): CalteFormatFieldData {
        this.customerInput = customerInput;
        return this;
    }

    public withCustomerPublish(customerPublish: boolean): CalteFormatFieldData {
        this.customerPublish = customerPublish;
        return this;
    }
}