




























import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import Vue from "vue";
import {AIEstheGetterTypes, AIEstheMutationTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import AILoading from "@/components/infrastructures/atoms/designed/AILoading.vue";

@Component({
  components: {
    AILoading,
    DMVPlayer,
    PrimaryBtn, VerticalSlotContents, KawaiiButton
  }
})
export default class VibrationExperienceStep extends Step {

  name = "VibrationExperienceStep";
  playing = false;
  faceExpSoundResultId = '';
  soundSrc = '';
  loading = true;

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    if (this.soundSrc === '') {
      this.faceExpSoundResultId = this.$store.getters[AIEstheGetterTypes.getId];
      this.soundSrc = this.$store.getters[AIEstheGetterTypes.getSoundSrc];
    }
  }

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  getStepTitle(): string {
    return "AIエステ.顔診断エステ.振動体験";
  }

  receiveDateTimeOnStopped(id: string, dateTime: StopWatchDateTime) {
    this.$store.commit(AIEstheMutationTypes.setDateTime, dateTime);
  }

  startStopListener(playing: boolean) {
    this.playing = playing;
  }

  completeVibrationExperience() {

    const dateTime: StopWatchDateTime = this.$store.getters[AIEstheGetterTypes.getDateTime];

    const request = {
      dmvTypeCode: "01",
      planCode: Plan.FACE_ANALYZE.code,
      faceExpSoundResultId: this.faceExpSoundResultId,
      startDateTime: dateTime.formattedStartDateTime,
      endDateTime: dateTime.formattedEndDateTime,
      totalPlayingTime: dateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-face-playing", request);

    this.next();
  }
}
Vue.component(VibrationExperienceStep.name, VibrationExperienceStep);
