







import {Component, Vue} from "vue-property-decorator";
import KawaiiCalendar from "@/components/domains/calender/KawaiiCalendar.vue";
import DetectFaceResultCard from "@/components/domains/esthe/aiesthe/plans/detectface/DetectFaceResultCard.vue";
import {AuthGetterTypes} from "@/store/auth/types";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";

@Component({
  components: {MyPageContentTemplate, KawaiiDialog, DetectFaceResultCard, KawaiiCalendar}
})
export default class EstheCalendarPage extends Vue {

  name = "EstheCalendarPage";
  results: any[] = [];
  user = this.$store.getters[AuthGetterTypes.getCurrentUser];
}
