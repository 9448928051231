import {RootState} from "@/store/type";
import {Module} from "vuex";
import {EnterpriseState} from "@/store/enterprise/types";
import getters from "@/store/enterprise/getters";
import mutations from "@/store/enterprise/mutations";

const state: EnterpriseState = {
    oldestStoppedTime: new Date(1)
}

export const enterprise: Module<EnterpriseState, RootState> = {
    namespaced: false,
    state,
    getters,
    mutations
}
