

import {Component, Vue} from "vue-property-decorator";
import AdvertiseService from "@/components/domains/advertise/AdvertiseService";

@Component
export default class AdvertisePage extends Vue {

  val = '';

  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('request') && params.has('type') && params.has('key')) {
      const request = params.get('request') as string;
      const type = params.get('type') as string;
      const key = params.get('key') as string;

      AdvertiseService.notice(key, type, request)
          .then(() => {
            if (request === 'cancel') {
              alert('メール配信の解除を行いました。')
            } else if (request === 'continue') {
              alert('メール配信の利用設定を行いました。')
            }
          })
          .finally(() => {
            this.$router.push('/');
          })
    } else {
      this.$router.push('/');
    }
  }
}
