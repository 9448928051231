









import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import AuthService from "@/components/domains/authorization/authService";

@Component({
  components: {HoverLinkCard}
})
export default class MentalCheckInductionHoverLink extends Vue {
  name = "MentalCheckInductionHoverLink";

  canAnyMentalCheck = false;

  get isEnterprise(): boolean {
    return AuthService.isEnterprise();
  }

  created() {
    httpResource.get(`/mental-check/can-any-mentalcheck`)
        .then((response: any) => {
          this.canAnyMentalCheck = response.data;
        });
  }
}
