

































import {Component, Model, Vue} from "vue-property-decorator";
import QuestionData from "@/components/infrastructures/functions/questionnaire/questionData";

@Component
export default class StressCheckQuestionnaire extends Vue {

  public name = "StressCheckQuestionnaire";

  @Model() private questions!: QuestionData[];

}
