import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class MentalCheckService {

  public static answerUn64(answer: any) {
    return httpResource.post("/v2/aiesthe/interview/answer/un64", answer);
  }

  public static answerUp65(answer: any) {
    return httpResource.post("/v2/aiesthe/interview/answer/up65", answer);
  }
}
