










import {Component, Vue} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import AgreementService from "@/components/domains/agreement/agreementService";

@Component({
  components: {KawaiiDialog}
})
export default class ProvidingDataAgreementDialog extends Vue {

  name = 'ProvidingDataAgreementDialog';
  dialog = false;

  created() {
    AgreementService.isAgreementProvidingData()
        .then((response: any) => {
          this.dialog = !response.data;
        });
  }

  private agree(agree: boolean) {
    this.$emit("agree", agree);
    AgreementService.agreeProvidingData(agree).finally(() => this.dialog = false);
  }
}
