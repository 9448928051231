import httpResource from "@/components/infrastructures/functions/http/httpResource";
import store from "@/store";
import {AuthGetterTypes, AuthMutationTypes} from "@/store/auth/types";
import {User} from "@/components/domains/authorization/user";
import PageSizeService from "@/components/domains/settings/pagesize/pageSizeService";

export default class AuthService {

    static INTERVAL_MILLI_SECONDS = 1800000; // 30 minutes

    public static isPremiumAccount(): boolean {
        return this.isLoggedIn() && store.getters[AuthGetterTypes.getCurrentUser].premium;
    }

    public static isLoggedIn(): boolean {
        return store.getters[AuthGetterTypes.getIsAuthenticated];
    }

    public static isEnterprise(): boolean {
        return this.isLoggedIn() && store.getters[AuthGetterTypes.getCurrentUser].enterprise;
    }

    public static isAdmin(): boolean {
        return this.isLoggedIn() && store.getters[AuthGetterTypes.getCurrentUser].roles.indexOf("ROLE_ADMIN") !== -1;
    }

    public static async login(mailAddress: string, password: string): Promise<User> {

        const loginInfo = {
            loginId: mailAddress,
            password: password
        };

        const response = await httpResource.post('/auth/login', loginInfo);

        if (response.status === 200) {
            await AuthService.authMe();
        }

        return await store.getters[AuthGetterTypes.getCurrentUser];
    }

    public static async logout() {

        const intervalName = store.getters[AuthGetterTypes.getIntervalName];
        if (intervalName) {
            clearInterval(intervalName);
        }

        store.commit(AuthMutationTypes.setAuthenticated, false);
        store.commit(AuthMutationTypes.clearCurrentUser);

        await httpResource.get('auth/logout/');
    }

    public static async authMe() {

        try {

            const response = await httpResource.post("/auth/me");

            if (response.status === 200 && response.data) {

                const currentUser = response.data;

                store.commit(AuthMutationTypes.setAuthenticated, true);
                store.commit(AuthMutationTypes.setCurrentUser, currentUser);

                const intervalName = setInterval(async () => {
                    await AuthService.refreshTokenInterval();
                }, AuthService.INTERVAL_MILLI_SECONDS);

                store.commit(AuthMutationTypes.setIntervalName, intervalName);

                // TODO ページサイズ適用
                await PageSizeService.fetchScale();

            } else {
                await AuthService.logout();
            }

        } catch (error) {
            await AuthService.logout();
        }
    }

    public static async refreshTokenInterval() {
        try {
            const response = await httpResource.post("/auth/refresh");
            if (response.status !== 200) {
                await AuthService.logout();
            }
        } catch (error) {
            await AuthService.logout();
        }
    }
}