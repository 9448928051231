


































import FieldData from "@/components/infrastructures/functions/fieldData";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class EnterpriseUploadTab extends Vue {

  name = "EnterpriseUploadTab.vue";

  // dialog
  dialog = false;
  dialogMessage = "";

  // register
  experience = true;
  withMusic = false;
  selectedDMVName = "";
  selectedDMVBin = "";
  disabledUpload = true;

  txtDMVName = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("DMV ファイル名");

  selected() {
    const file = (this.$refs.input as any).files[0];

    this.selectedDMVName = file.name;
    if (this.txtDMVName.value === "") {
      this.txtDMVName.value = this.selectedDMVName;
    }

    const reader = new FileReader();
    reader.onload = (evt: any) => {
      this.selectedDMVBin = evt.target.result;
    }
    reader.readAsDataURL(file)
  }

  openFileSelector() {
    (this.$refs.input as any).click();
  }

  validate(): boolean {
    this.disabledUpload =
        this.selectedDMVBin === ""

    // TODO agencyId 周りの判定を入れる

    return !this.disabledUpload;
  }

  upload() {

    if (!this.validate()) {
      return;
    }

    const data = {
      experience: this.experience,
      withMusic: this.withMusic,
      // TODO 一旦 null
      enterpriseAgencyId: null,
      roomId: null,
      roomSectionId: null,
      fileName: this.selectedDMVName,
      dmv: this.selectedDMVBin
    };

    this.disabledUpload = true;

    httpResource.post("/upload/enterprise-dmv", data)
        .then((response: any) => {
          this.dialogMessage = "正常に登録できました。"
        })
        .catch((error: any) => {
          this.dialogMessage = "登録に失敗しました。"
        })
        .finally(() => {
          this.dialog = true;
          this.validate();
        });

  }

  @Watch("selectedDMVBin")
  watchSelectedDMV() {
    this.validate();
  }
}
