












import {Component} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin"
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDaialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import PaymentHistoryTable from "@/components/domains/payment/histroy/PaymentHistoryTable.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PaymentService from "@/components/domains/payment/paymentService";

@Component({
  components: {MyPageContentTemplate, PaymentHistoryTable, KawaiiButton, KawaiiDaialog}
})
export default class PaymentHistoryPage extends AuthMixin {

  public name = "PaymentHistoryPage";

  // TODO 購入履歴件数を取得する
  private historyCount = 0;

  beforeCreate() {
    PaymentService.fetchHistoryCount().then(count => {
      this.historyCount = count;
    });
  }
}
