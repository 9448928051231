





























import {Component, Prop} from "vue-property-decorator";
import WindowSize from "@/components/infrastructures/functions/window/windowSize";
import {VBtn} from "vuetify/lib";

@Component
export default class PrimaryBtn extends VBtn {

  name = "PrimaryBtn";

  @Prop({default: "85%"}) width!: string;
  @Prop({default: true}) rounded!: boolean;
  @Prop({default: false}) outlined!: boolean;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: 'secondary'}) color!: string;
  @Prop({default: ''}) to!: string;

  clickAction(event: any) {
    this.$emit('click', event)
  }

  get isSmartphoneSize() {
    return WindowSize.isSmartphoneSize(this);
  }
}
