


































import {Component, Prop} from "vue-property-decorator";
import {VCard} from "vuetify/lib";

@Component
export default class HoverLinkCard extends VCard {
  name = "HoverLinkCard";

  @Prop() title: string;
  @Prop() subtitle: string;
  @Prop() to: string;
  @Prop() img: string;
  @Prop() textSize: string;
  @Prop({default: 5}) py: number;
  @Prop({default: 3}) my: number;
  @Prop({default: '#fccbcf'}) borderColor!: string;
  @Prop({default: false}) disabled: boolean;
}
