






import {Component} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin"
import StepContents, {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import CreditCardStep from "@/components/domains/payment/shared/steps/CreditCardStep.vue";
import ConfirmationStep from "@/components/domains/payment/shared/steps/ConfirmationStep.vue";
import {PurchaseGetterTypes, PurchaseMutationTypes} from "@/store/product/purchase/types";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import AgreementsStep from "@/components/domains/agreement/AgreementsStep.vue";
import ProductService from "@/components/domains/products/productService";
import DeliveryAddressStep from "@/components/domains/payment/shared/steps/DeliveryAddressStep.vue";

@Component({
  components: {MyPageContentTemplate, StepContents}
})
export default class SubscriptionForm extends AuthMixin {

  public name = "SubscriptionForm";
  private steps: Step[] = [];
  private createdStep = false;

  beforeMount() {

    this.$store.commit(
        PurchaseMutationTypes.setUsers, [this.$store.getters[AuthGetterTypes.getCurrentUser].userId]);

    // 一般の場合
    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_GENERAL"})
        .then((hasRole: boolean) => {
          if (hasRole) {
            this.setSteps(false);
          }
        });

    // 顧客の場合
    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_CUSTOMER"})
        .then((hasRole: boolean) => {
          if (hasRole) {
            this.setSteps(false);
          }
        });

    // 担当者の場合
    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_PERSON_IN_CHARGE"})
        .then((hasRole: boolean) => {
          if (hasRole) {
            this.setSteps(true);
          }
        });

    // 管理者の場合
    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_ADMIN"})
        .then((hasRole: boolean) => {
          if (hasRole) {
            this.setSteps(true);
          }
        });
  }

  private setSteps(greaterPic: boolean) {
    const productId = this.$store.getters[PurchaseGetterTypes.getProductId];
    if (!productId) {
      alert("商品を選択してください。");
      this.$router.push("/premium-plans")
    }

    ProductService.requiresDeliveryAddress(productId).then(exists => {
      this.steps = [];

      this.steps.push(new AgreementsStep());
      if (exists) {
        this.steps.push(new DeliveryAddressStep());
      }
      if (greaterPic) {
        // TODO 配送先関連の問題があるので保留
        // this.steps.push(new UserSelectStep());
      }
      this.steps.push(new CreditCardStep());
      this.steps.push(new ConfirmationStep());
      this.createdStep = true;
    });
  }
}
