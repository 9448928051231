import CalteFormat from "@/components/domains/calte/calteFormat";
import CalteInputRecord from "@/components/domains/calte/calteInputRecord";
import CalteRecord from "@/components/domains/calte/calteRecord";

export class CalteFormatConverter {

    public static format2record(id: string, calteFormat: CalteFormat): CalteRecord {
        const calteInputRecords: CalteInputRecord[] = calteFormat.calteItems.map(item => {
            return new CalteInputRecord(item.id, item.value);
        });
        const calteRecord: CalteRecord = new CalteRecord(id, calteFormat.calteId, calteInputRecords);
        return calteRecord;
    }
}