export default class AgreementsStore {

    public static agreeTermsOfService = false;
    public static agreeProvidingData = false;

    public static reset() {
        AgreementsStore.agreeTermsOfService = false;
        AgreementsStore.agreeProvidingData = false;
    }

    public static agreeAll(): boolean {
        return AgreementsStore.agreeTermsOfService && AgreementsStore.agreeProvidingData;
    }
}