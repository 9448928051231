





































import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";


@Component({
  components: {KawaiiButton}
})
export default class EasilyLoginAgreement extends Vue {
  private name = "EasilyLoginAgreement";

  disagreeEasilyLogin(): void {
    httpResource.post('/easily-login/agree?agree=false')
        .then((response: any) => {
          this.$router.push('/mypage/settings');
        });
  }

  agreeEasilyLogin(): void {
    httpResource.post('/easily-login/agree?agree=true')
        .then((response: any) => {
          this.$router.push('/mypage/settings/easily-login/issue-url');
        });
  }
}
