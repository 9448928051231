<template>
  <v-footer
      color="primary"
      class="pt-md-10 mt-md-10">
    <v-row>

      <v-col class="col-12">
        <!--          <v-card-text align="center">-->
        <!--            <a class="white&#45;&#45;text font-weight-bold"-->
        <!--               href="https://docs.google.com/forms/d/e/1FAIpQLScmcAI63dX6gdED8iDRD-1jR6lp-TpxEoJVnJwk2dEmWJW3vA/viewform?usp=sf_link"-->
        <!--               target="_blank">お問い合わせ</a>-->
        <!--          </v-card-text>-->

        <copyright/>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import Copyright from "@/components/infrastructures/atoms/Copyright";

export default {
  name: "DefaultFooter",
  components: {Copyright}
}
</script>

<style scoped>

</style>