






import {Component, Vue} from "vue-property-decorator";
import DeliveryAddressExtForm from "@/components/domains/account/profile/address/DeliveryAddressExtForm.vue";

@Component({
  components: {DeliveryAddressExtForm}
})
export default class AddressInformation extends Vue {
  name = "AddressInformation";
}
