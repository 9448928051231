






import {Component, Vue} from "vue-property-decorator";
import {Chart} from "chart.js";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class MonthlyActivityChart extends Vue {
  name = "MonthlyActivityChart";

  loaded = false;
  currentChart!: Chart;

  mounted() {

    if (this.currentChart) {
      this.currentChart.destroy();
    }

    httpResource.get("/v2/activity/monthly")
        .then((response: any) => {

          const aiEstheTime = response.data
              .map((a: any) => a.aiEstheTime)
              .map((a: any) => (a.hour * 60) + a.minutes + (a.seconds > 0 ? a.seconds / 60 : 0));

          const medicalSoundTime = response.data
              .map((a: any) => a.medicalSoundTime)
              .map((a: any) => (a.hour * 60) + a.minutes + (a.seconds > 0 ? a.seconds / 60 : 0));

          const labels = response.data.map((a: any) => a.label);

          const data = {
            labels: labels,
            datasets: [
              {
                label: 'AI エステ',
                fill: false,
                backgroundColor: 'rgb(132, 99, 255)',
                borderColor: 'rgb(132, 99, 255)',
                data: aiEstheTime,
              },
              {
                label: 'メディカルサウンド',
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: medicalSoundTime,
              },
            ]
          };

          const config = {
            type: 'line',
            data: data,
            options: {
              scales: {
                y: {
                  title: {
                    display: true,
                    text: 'ご利用時間（分）',
                  },
                  suggestedMin: 50
                }
              }
            }
          };

          this.currentChart = new Chart(
              (document.getElementById('myChart') as any),
              config as any
          );

          this.loaded = true;
        });
  }

}
