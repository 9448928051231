























import {Component, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import EnterpriseRoom from "@/components/domains/enterprise/dto/enterpriseRoom";
import EnterpriseService from "@/components/domains/enterprise/enterpriseService";
import EnterpriseSection from "@/components/domains/enterprise/dto/enterpriseSection";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import EnterpriseSectionManagement from "@/components/domains/enterprise/dto/enterpriseSectionManagement";

@Component({
  components: {SmartDMVPlayer, MyPageContentTemplate}
})
export default class EnterpriseControlPlanPage extends Vue {
  name = "EnterpriseControlPlanPage";
// load
  fetchIntervalId = 0;

  // section
  sectionId = this.$route.params.sectionId;
  selectedSection: EnterpriseSection | undefined
  selectedManagement: EnterpriseSectionManagement
  sectionLoaded = false;
  createdLoaded = false;

  stopping = true; // 再生指示
  stoppingCount = 0;
  actStopping = true; // 実際に再生されているか
  audio: IndexedAudio;

  // room
  rooms: EnterpriseRoom[] = []

  created() {
    this.createdLoaded = false;
    this.fetchRooms();
    this.createdLoaded = true;
  }

  beforeDestroy() {
    clearInterval(this.fetchIntervalId);
  }

  fetchRooms() {
    EnterpriseService.rooms().then((result: EnterpriseRoom[]) => {
      this.rooms = result;
    })
  }

}
