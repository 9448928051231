














import {Component, Prop, Vue} from "vue-property-decorator";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import WindowSize from "@/components/infrastructures/functions/window/windowSize";

@Component({
  components: {ContentTitle}
})
export default class MyPageContentTemplate extends Vue {
  name = "MyPageContentTemplate";
  @Prop({default: ''}) title!: string;
  @Prop({default: ''}) subtitle!: string;

  get isSmartphoneSize() {
    return WindowSize.isSmartphoneSize(this);
  }
}
