import {Module} from "vuex";
import {AIEstheState} from "@/store/aiesthe/types";
import {RootState} from "@/store/type";
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";

const state: AIEstheState = {
    id: '',
    course: 1,
    after: false,
    image: '',
    soundId: '',
    soundSrc: '',
    publish: false,
    bodyPart: '',
    dateTime: new StopWatchDateTime(0, 0, 0),
    detectFaceResult: {}
}

export const aiesthe: Module<AIEstheState, RootState> = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
