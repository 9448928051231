

























































import {Component, Prop, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PaymentService from "@/components/domains/payment/paymentService";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import CreditCardSearchTable from "@/components/domains/payment/creditcard/CreditCardSearchTable.vue";
import CreditCardForm from "@/components/domains/payment/creditcard/CreditCardForm.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import SubscriptionService from "@/components/domains/payment/shared/subscription/SubscriptionService";

@Component({
  components: {PrimaryBtn, CreditCardForm, CreditCardSearchTable, SubBtn, MyPageContentTemplate}
})
export default class PaymentDetail extends Vue {
  name = "PaymentDetail";

  @Prop() paymentId!: string;
  history: any = {};

  // change card
  disabledChangeCardBtn = false;
  selectedCard: { id: string }[] = [];

  beforeMount() {
    PaymentService.fetchHistory(this.paymentId)
        .then(history => {
          this.history = history;
        })
        .catch(() => {
          alert('購入情報が見つかりませんでした。');
          this.$router.push('/mypage/subscription-state');
        })
  }

  updateCard() {
    this.disabledChangeCardBtn = true;
    SubscriptionService.updateCard(this.paymentId, this.selectedCard[0].id)
        .then(() => {
          alert("お支払用カードの変更に成功しました。");
          this.$router.push({path: `/mypage/subscription-state`});
        })
        .catch(error => {
          alert(error.response.data.message);
        })
        .finally(() => {
          this.disabledChangeCardBtn = false;
        });
  }
}
