








import {Component, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import SoundTherapySelectCourseStep
  from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapySelectCourseStep.vue";
import SoundTherapyExperienceStep
  from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapyExperienceStep.vue";
import SoundTherapySelectStep
  from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapySelectStep.vue";

@Component({
  components: {MyPageContentTemplate}
})
export default class AIEstheSoundTherapyPage extends Vue {
  name = "AIEstheSoundTherapyPage";

  steps: Step[] = [
      new SoundTherapySelectCourseStep(),
      new SoundTherapySelectStep(),
      new SoundTherapyExperienceStep()
  ];
}
