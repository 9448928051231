





































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Chat extends Vue {
  name = "Chat";

  @Prop({default: []}) chats!: {}[];
}
