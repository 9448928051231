import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class PaymentService {

  public static cancel(histories: { id: string }[]) {
    const request = {
      paymentHistoryIds: histories.map(history => (history as any).id)
    }
    return httpResource.post(`v3/subscription/cancel`, request);
    // return httpResource.post(`payment/cancel`, request);
  }

  public static previewCancel(histories: { id: string }[]) {
    // const request = {
    //   paymentHistoryIds: histories.map(history => (history as any).id)
    // }
    // return httpResource.post(`v3/subscription/prev-cancel`, request);
    const request = {
      historyIds: histories.map(history => (history as any).id)
    }
    return httpResource.post(`payment/prev-cancel`, request);
  }

  public static async fetchHistoryCount() {
    return (await httpResource.get('/v2/payment/fetch-history-count')).data;
  }

  public static async fetchHistory(id: string) {
    return (await httpResource.get(`/v2/payment/fetch-history?id=${id}`)).data;
  }
}
