import QuestionTreeData from "@/components/infrastructures/functions/question/questionTreeData";
import Answer from "@/components/infrastructures/functions/question/answer";
import MentalCheckQuestionResult
  from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckQuestionResult";
import Question from "@/components/infrastructures/functions/question/question";

export default class MentalCheckQuestionHolder {

  public static createUnder64Tree(): QuestionTreeData {

    // Q1
    const q1a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q1a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 不眠
    const q1 = new Question("夜眠れていますか。", [q1a1, q1a2]);

    const tree = new QuestionTreeData(q1);

    // Q2
    const q2a1 = new Answer("はい", MentalCheckQuestionResult.YES); // 脳の疲れ
    const q2a2 = new Answer("いいえ", MentalCheckQuestionResult.NO);
    const q2 = new Question("食欲はありますか。", [q2a1, q2a2]);

    tree.add(q1a1, q2);
    tree.add(q1a2, q2);

    // Q3
    const q3a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q3a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 自律神経の乱れ
    const q3 = new Question("たいていは機嫌よく過ごすことが多いですか。", [q3a1, q3a2]);

    tree.add(q2a1, q3);
    tree.add(q2a2, q3);

    // Q4
    const q4a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q4a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 鬱傾向
    const q4 = new Question("日中、集中力が続かないことが多いですか。", [q4a1, q4a2]);

    tree.add(q3a1, q4);
    tree.add(q3a2, q4);

    // Q5
    const q5a1 = new Answer("頭", MentalCheckQuestionResult.HEAD);
    const q5a2 = new Answer("目", MentalCheckQuestionResult.EYES);
    const q5a3 = new Answer("首", MentalCheckQuestionResult.NECK);
    const q5a4 = new Answer("胃", MentalCheckQuestionResult.IN_STOMACH);
    const q5a5 = new Answer("お腹", MentalCheckQuestionResult.SURFACE_STOMACH);
    const q5a6 = new Answer("足全体", MentalCheckQuestionResult.LEGS);
    const q5a7 = new Answer("膝", MentalCheckQuestionResult.KNEES);
    const q5a8 = new Answer("こころ", MentalCheckQuestionResult.HEART);
    const q5a9 = new Answer("特になし", MentalCheckQuestionResult.NOTHING);
    const q5 = new Question("どこが気になりますか？", [q5a1, q5a2, q5a3, q5a4, q5a5, q5a6, q5a7, q5a8, q5a9]);

    tree.add(q4a1, q5);
    tree.add(q4a2, q5);

    return tree;
  }

  public static createUpper65Tree(): QuestionTreeData {

    // Q1
    const q1a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q1a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 不眠
    const q1 = new Question("夜眠れていますか。", [q1a1, q1a2]);

    const tree = new QuestionTreeData(q1);

    // Q2
    const q2a1 = new Answer("はい", MentalCheckQuestionResult.YES); // 脳の疲れ
    const q2a2 = new Answer("いいえ", MentalCheckQuestionResult.NO);
    const q2 = new Question("食欲はありますか。", [q2a1, q2a2]);

    tree.add(q1a1, q2);
    tree.add(q1a2, q2);

    // Q3
    const q3a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q3a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 自律神経の乱れ
    const q3 = new Question("たいていは機嫌よく過ごすことが多いですか。", [q3a1, q3a2]);

    tree.add(q2a1, q3);
    tree.add(q2a2, q3);

    // Q4
    const q4a1 = new Answer("はい", MentalCheckQuestionResult.YES);
    const q4a2 = new Answer("いいえ", MentalCheckQuestionResult.NO); // 鬱傾向

    const q4 = new Question("親しい友人、知人の名前を忘れることがありますか。", [q4a1, q4a2]);

    tree.add(q3a1, q4);
    tree.add(q3a2, q4);

    // Q5
    const q5a1 = new Answer("頭", MentalCheckQuestionResult.HEAD);
    const q5a2 = new Answer("目", MentalCheckQuestionResult.EYES);
    const q5a3 = new Answer("首", MentalCheckQuestionResult.NECK);
    const q5a4 = new Answer("胃", MentalCheckQuestionResult.IN_STOMACH);
    const q5a5 = new Answer("お腹", MentalCheckQuestionResult.SURFACE_STOMACH);
    const q5a6 = new Answer("足全体", MentalCheckQuestionResult.LEGS);
    const q5a7 = new Answer("膝", MentalCheckQuestionResult.KNEES);
    const q5a8 = new Answer("こころ", MentalCheckQuestionResult.HEART);
    const q5a9 = new Answer("特になし", MentalCheckQuestionResult.NOTHING);
    const q5 = new Question("どこが気になりますか？", [q5a1, q5a2, q5a3, q5a4, q5a5, q5a6, q5a7, q5a8, q5a9]);

    tree.add(q4a1, q5);
    tree.add(q4a2, q5);

    return tree;
  }
}
