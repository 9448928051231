






























import {Component} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import AILoading from "@/components/infrastructures/atoms/designed/AILoading.vue";

@Component({
  components: {AILoading, PrimaryBtn, SmartDMVPlayer}
})
export default class EnterpriseExperiencePlanPage extends AuthMixin {
  name = "EnterpriseExperiencePlanPage"
  soundId = '';
  soundSrc = '';
  playing = false;
  playingDateTime = new StopWatchDateTime();

  beforeMount() {

    httpResource.get('/enterprise/random')
        .then((response: any) => {
          this.setSoundId(response.data.id);
          this.setSoundSrc(response.data.soundSrc);
        })
        .catch((error: any) => {
          // console.log(error)
          alert('振動選択に失敗しました。');
        });
  }

  setSoundId(soundId: string) {
    this.soundId = soundId;
  }

  setSoundSrc(soundSrc: string) {
    this.soundSrc = soundSrc;
  }

  receiveDateTimeOnStarted() {
    this.playing = true;
  }

  receiveDateTimeOnStopped(audio: IndexedAudio, dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
    this.playing = false;
  }

  finish() {

    const request = {
      dmvTypeCode: "00", // とりあえず固定値（Enterpriseであることを示す）
      planCode: '01', // とりあえず固定値（おまかせを示す）
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/enterprise/");
  }

  get getSoundSrc() {
    return this.soundSrc;
  }
}
