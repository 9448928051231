import { render, staticRenderFns } from "./SelectCourseStep.vue?vue&type=template&id=215e8d32&scoped=true&"
import script from "./SelectCourseStep.vue?vue&type=script&lang=ts&"
export * from "./SelectCourseStep.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "215e8d32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VCard,VRadioGroup})
