import { render, staticRenderFns } from "./DeliveryAddressExtForm.vue?vue&type=template&id=404ed216&scoped=true&"
import script from "./DeliveryAddressExtForm.vue?vue&type=script&lang=ts&"
export * from "./DeliveryAddressExtForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "404ed216",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VBtn,VCard,VCheckbox,VDivider,VExpandTransition,VFadeTransition})
