import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import GlobalComponentRegister from "@/plugins/globalComponentRegister";
import i18n from "@/plugins/i18n";
import ChartJs from "@/plugins/chartJs";
import * as log from "loglevel";

Vue.config.productionTip = false;

const logLevel = process.env.VUE_APP_DEVELOPMENT_MODE === 'true' ? log.levels.DEBUG : log.levels.INFO
log.setLevel(logLevel, false);

ChartJs.register();
GlobalComponentRegister.register();

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    i18n: i18n,
    render: h => h(App)
}).$mount('#app')
