import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {EnterpriseGetterTypes, EnterpriseState} from "@/store/enterprise/types";

const getters: GetterTree<EnterpriseState, RootState> = {
    [EnterpriseGetterTypes.getOldestStoppedTime]: (state: EnterpriseState): {} => {
        return state.oldestStoppedTime;
    }
}

export default getters;