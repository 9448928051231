




































































import {Component, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import DeliveryAddressForm from "@/components/domains/account/profile/address/DeliveryAddressForm.vue";
import {User} from "@/components/domains/authorization/user";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";
import AddressService from "@/components/domains/account/profile/address/addressService";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import DeliveryAddressDisplayCard from "@/components/domains/account/profile/address/DeliveryAddressDisplayCard.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import AddressForm from "@/components/domains/account/profile/address/old/AddressForm.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import {PurchaseGetterTypes, PurchaseMutationTypes} from "@/store/product/purchase/types";

@Component({
  components: {
    DeliveryAddressDisplayCard,
    SubBtn,
    PrimaryBtn, DeliveryAddressForm, HoverLinkCard, KawaiiDialog, AddressField: AddressForm, KawaiiButton
  }
})
export default class DeliveryAddressExtForm extends Vue {
  name = "DeliveryAddressExtForm";

  @VModel() selected: DeliveryAddress;
  @Prop() user!: User;
  @Prop({default: false}) showCheckbox: boolean;

  // dialog
  dialog = false;
  dialogMessage = '';

  // address dialog
  showAddressForm = false;

  // edit
  editAddressIdx = -1;

  // addresses
  deliveryAddress: DeliveryAddress = new DeliveryAddress();
  deliveryAddresses: DeliveryAddress[] = [];

  // checkbox
  checkedIndexes: boolean[] = [];

  // component
  @Prop({default: '登録する'}) registerBtnName: string;
  @Prop({default: false}) hideCancel: boolean;

  beforeMount() {
    this.updateDeliveryAddresses();

    const selectedAddressId = this.$store.getters[PurchaseGetterTypes.getDeliveryAddressId];
    if (!selectedAddressId) {
      this.deliveryAddresses.forEach((address, i) => {
        if (address.regulation) {
          this.checkedIndexes[i] = true;
        }
      })
    }
  }

  @Watch('checkedIndexes')
  updateSelectedAddress() {
    let tmp = new DeliveryAddress();
    this.checkedIndexes.forEach((t, i) => {
      // 既に選択されているもののチェックを外す
      if (this.selected.addressId === this.deliveryAddresses[i].addressId) {
        this.checkedIndexes[i] = false;
      }
      if (t) {
        tmp = this.deliveryAddresses[i];
      }
    });
    this.selected = tmp;
    this.$store.commit(PurchaseMutationTypes.setDeliveryAddressId, tmp.deliveryId);
    this.$emit("selected", tmp);
  }

  get getDeliveryAddresses() {
    return this.deliveryAddresses;
  }

  updateDeliveryAddresses() {

    const selectedAddressId = this.$store.getters[PurchaseGetterTypes.getDeliveryAddressId];

    AddressService.fetchDeliveryAddresses()
        .then(addresses => {
          this.deliveryAddresses = addresses;
          this.checkedIndexes = this.deliveryAddresses.map((address) => {
            if (selectedAddressId === address.addressId) {
              return true;
            }
            return false;
          });
        })
        .catch(error => {
          // console.log(error);
        });
  }

  clickUpdate() {

    const data = {
      userId: this.user.userId,
    };

    this.dialog = false;
    httpResource.post('/profile/update', data)
        .then(() => {
          this.$store.dispatch(AuthActionTypes.authenticateUser);
          this.user = this.$store.getters[AuthGetterTypes.getCurrentUser];
          this.dialogMessage = '更新に成功しました。';
        })
        .catch(() => {
          this.dialogMessage = '更新に失敗しました。';
        })
        .finally(() => {
          this.dialog = true;
        });
  }

  private registerDeliveryNew() {

    this.$emit("beforeRegister", this.deliveryAddress);

    // TODO validation
    // 登録
    AddressService.registerDelivery(this.deliveryAddress)
        .then(() => {
          alert('登録しました。');
          this.updateDeliveryAddresses();
        })
        .catch(error => {
          alert(error.response.data.message);
          return;
        });

    // 送付先住所一覧取得
    this.showAddressForm = false;

    this.$emit("afterRegister", this.deliveryAddress);

    // 初期化
    (this.$refs.deliveryAddress as DeliveryAddressForm).reset();
  }

  private updateDelivery(index: number) {

    const address = this.deliveryAddresses[index];
    AddressService.updateDelivery(address)
        .then(() => {
          alert('更新しました。');
          this.updateDeliveryAddresses();

          // 閉じる
          this.editAddressIdx = -1;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
  }

  private deleteDelivery(index: number) {

    const address = this.deliveryAddresses[index];
    AddressService.deleteDelivery(address)
        .then(() => {
          alert('削除しました。');
          this.updateDeliveryAddresses();
          this.editAddressIdx = -1;
        })
        .catch(error => {
          alert(error.response.data.message);
        });
  }

  private close() {
    this.showAddressForm = false;
    (this.$refs.deliveryAddress as DeliveryAddressForm).reset();
    this.updateDeliveryAddresses();
  }
}
