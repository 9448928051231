import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {PageSizeGetterTypes, PageSizeState} from "@/store/pagesize/types";

const getters: GetterTree<PageSizeState, RootState> = {
    [PageSizeGetterTypes.getScale]: (state: PageSizeState): {} => {
        return state.scale;
    }
}

export default getters;