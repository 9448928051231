import EnterpriseDmv from "@/components/domains/enterprise/dto/enterpriseDmv";

export default class EnterpriseSectionManagement {
    public id = "";
    public stTime = "";
    public edTime = "";
    public playing = false;
    public dmv: EnterpriseDmv = new EnterpriseDmv();

    public showStTime = false;

    public showEdTime = false;

    public message = "";

    public volume = 0.5;
}