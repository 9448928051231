
export default class PasswordSpecification {

  public static verifyPassword(password: string) {

    const trimmedPass = password.trim();

    if (trimmedPass === '') {
      return false;
    }

    if (trimmedPass.length < 8) {
      return false;
    }

    return true;
  }

  public static checkVerifyPasswords(pass1: string, pass2: string) {

    if (!this.verifyPassword(pass1) || !this.verifyPassword(pass2)) {
      return false;
    }

    const trimmedPass1 = pass1.trim();
    const trimmedPass2 = pass2.trim();

    if (trimmedPass1 !== trimmedPass2) {
      return false;
    }

    return true;
  }
}
