
















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DefaultVue from "vue";

@Component
export default class CustomerDisplayTable extends Vue {
  name = "CustomerDisplayTable";

  @Prop({default: {name: null}}) value!: any;

  customers: any[] = [];

  customerHeader = [
    {text: "顧客ID", value: "id"},
    {text: "顧客名", value: "name"},
    {text: "メールアドレス", value: "email"}
  ];

  @Watch("value")
  updateCustomers() {
    this.customers = [this.value];
  }
}

DefaultVue.component("CustomerDisplayTable", CustomerDisplayTable);
