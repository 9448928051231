export default class MyPageLinkHolder {

    public static title_活動 = {type: "title", name: "活動", link: ""};
    public static title_その他 = {type: "title", name: "その他", link: ""};
    public static title_カルテ = {type: "title", name: "カルテ", link: "", icon: 'mdi-note-text'};
    public static title_エステ = {type: "title", name: "エステ", description: "名古屋大学情報学部共同研究", link: "", icon: ''};
    public static title_エンタープライズ = {type: "title", name: "エンタープライズ", description: "", link: "", icon: ''};
    public static title_サブスクリプション = {type: "title", name: "サブスクリプション", link: ""};

    public static link_認可認証 =
        {type: "link", name: "申請手続き", link: "/mypage/authorization", icon: 'mdi-check-decagram'};
    public static link_エンタープライズ = {
        type: "link",
        name: "エンタープライズサービス",
        link: "/mypage/enterprise",
        icon: 'mdi-office-building'
    };
    public static link_AI_ESTHE = {
        type: "link",
        name: "AI エステ",
        link: "/mypage/aiesthe",
        // icon: 'mdi-vibrate',
        img: '/img/kaorythm_panel.jpeg'
    };
    public static link_メディカルサウンド = {
        type: "link",
        name: "メディカルサウンド",
        link: "/mypage/soundesthe",
        // icon: 'mdi-cellphone-sound',
        img: '/img/soundesthe_panel.jpg'
    };
    public static link_奏 = {
        type: "link",
        name: "奏 －Kanade",
        link: "/mypage/kanade",
        icon: 'mdi-cellphone-play'
    };
    public static link_利用状況 = {type: "link", name: "利用状況", link: "/mypage/activity", icon: 'mdi-star-shooting'};
    public static link_カレンダー = {type: "link", name: "カレンダー", link: "/mypage/calendar", icon: 'mdi-calendar-month'};
    public static link_プロフィール = {
        type: "link",
        name: "プロフィール",
        link: "/mypage/profile",
        icon: 'mdi-card-account-details'
    };
    public static link_アップロード = {type: "link", name: "アップロード", link: "/mypage/upload", icon: 'mdi-upload'};
    public static link_各種設定 = {type: "link", name: "各種設定", link: "/mypage/settings", icon: 'mdi-cog'};
    public static link_管理画面 = {type: "outer", name: "管理画面", link: "http://admin.美容ai.com", icon: 'mdi-cog'};
    public static link_管理カレンダー = {type: "link", name: "管理カレンダー", link: "/mypage/mng-calendar", icon: 'mdi-calendar'};
    public static link_顔診断履歴 = {
        type: "link",
        name: "AI 顔診断履歴",
        link: "/mypage/aiesthe-history",
        icon: 'mdi-face-recognition'
    };
    public static link_カルテ管理 = {
        type: "link",
        name: "カルテ管理",
        link: "/mypage/calte-management",
        icon: 'mdi-notebook-edit'
    };
    public static link_カルテ一覧_顧客 = {
        type: "link",
        name: "カルテ一覧",
        link: "/mypage/fillout-calte",
        icon: 'mdi-note-multiple'
    };
    public static link_カルテ一覧_担当者 = {type: "link", name: "カルテ一覧", link: "/mypage/calte-list", icon: 'mdi-note-multiple'};
    public static link_お支払方法 = {
        type: 'link',
        name: 'お支払方法（カードの追加／削除）',
        link: '/mypage/payment-method',
        icon: 'mdi-currency-usd'
    }
    public static link_サブスク管理 = {
        type: "link",
        name: "カードの変更／解約",
        link: "/mypage/subscription-state",
        icon: 'mdi-currency-usd'
    };
    public static link_購入履歴 = {
        type: "link",
        name: "購入履歴",
        link: "/mypage/payment-history",
        icon: 'mdi-currency-usd'
    };
}