





















































import {Component} from "vue-property-decorator";
import TermsOfServiceText from "@/components/domains/agreement/TermsOfServiceText.vue";
import ProvidingDataAgreementText from "@/components/domains/agreement/ProvidingDataAgreementText.vue";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import InnerScrollCard from "@/components/infrastructures/atoms/designed/InnerScrollCard.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import AgreementsStore from "@/components/domains/account/register/general/agreementsStore";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import AgreementService from "@/components/domains/agreement/agreementService";

@Component({
  components: {
    BackBtn,
    PrimaryBtn,
    KawaiiDialog,
    InnerScrollCard,
    ProvidingDataAgreementText,
    TermsOfServiceText
  }
})
export default class AgreementsStep extends Step {
  name = "AgreementsStep";

  // Terms Of Service
  termsOfService = false;
  errorTermsOfService = false;
  termsOfServiceMsg = '';
  showTermsOfService = true;

  // Terms Of Service
  providingData = false;
  errorProvidingData = false;
  providingDataMsg = '';
  showProvidingData = true;

  getStepTitle(): string {
    return "利用規約の同意確認";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {

    this.reset();

    // TODO 同意状況を取得し、すでに同意済みの場合次へ進む
    // AgreementService.isAgreementTermOfService()
    // .then(response => {
    //   if (response.data) {
    //     this.showTermsOfService = false;
    //     this.termsOfService = true;
    //   }
    // });

    AgreementService.isAgreementProvidingData()
        .then(response => {
          if (response.data) {
            this.showProvidingData = false;
            this.providingData = true;
          }
        });

    if (this.termsOfService && this.providingData) {
      this.next();
    }
  }

  mounted() {
    super.mounted();
    this.reset();
  }

  protected validate(): boolean {

    this.errorTermsOfService = false;
    this.errorProvidingData = false;

    if (!this.termsOfService) {
      this.errorTermsOfService = true;
      this.termsOfServiceMsg = '次へ進むには同意する必要があります。';
    }
    if (!this.providingData) {
      this.errorProvidingData = true;
      this.providingDataMsg = '次へ進むには同意する必要があります。';
    }

    return this.termsOfService && this.providingData
  }

  reset() {
    this.errorTermsOfService = false;
    this.termsOfServiceMsg = '';
    this.errorProvidingData = false;
    this.providingDataMsg = '';
  }

  next() {

    if (!this.validate()) {
      return;
    }

    AgreementsStore.agreeTermsOfService = this.termsOfService;
    AgreementsStore.agreeProvidingData = this.providingData;
    super.next();
  }
}
