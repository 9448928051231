































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DMVUploadTab from "@/components/domains/upload/dmvupload/DMVUploadTab.vue";
import DMVDeleteTab from "@/components/domains/upload/dmvupload/DMVDeleteTab.vue";

@Component({
  components: {DMVDeleteTab, DMVUploadTab, KawaiiButton}
})
export default class DMVUpload extends Vue {

  name = "DMVUpload";
  tab: any = '';
}
