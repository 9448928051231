




















































import {Component, Watch} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin"
import CreditCardSearchTable from "@/components/domains/payment/creditcard/CreditCardSearchTable.vue";
import CreditCardRegister from "@/components/domains/payment/creditcard/CreditCardRegister.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import CreditCardInfo from "@/components/infrastructures/atoms/CreditCardInfo.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDaialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {CreditCardInfo, CreditCardSearchTable, CreditCardRegister, KawaiiButton, KawaiiDaialog}
})
export default class PaymentMethod extends AuthMixin {

  public name = "PaymentMethod";
  pulishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  title = ""
  message = ""
  confirmationDialog = false
  resultDialog = false
  hasError = false
  selectedCard = []
  isDeleteButtonDisabled = true
  updateKey = false;

  afterRegistered(result: {}) {
    if (!(result as any).hasError) {
      this.updateKey = !this.updateKey;
    }
  }

  setDialog(title: string, message: string, hasError: boolean) {
    this.title = title;
    this.message = message;
    this.hasError = hasError;
  }

  color() {
    if (this.hasError) {
      return "red"
    }
    return "green darken-1"
  }

  deleteCard() {
    const params = {
      id: (this.selectedCard[0] as any).id
    }
    httpResource.post(`credit-card/delete`, params).then(() => {
      this.setDialog("", "削除に成功しました", false);
    }).catch((error: any) => {
      this.setDialog("削除に失敗しました", error.response.data.message, true);
    }).finally(() => {
      this.resultDialog = true;
      this.confirmationDialog = false;
    })
  }

  refresh() {
    this.resultDialog = false
    this.updateKey = !this.updateKey
  }

  @Watch("selectedCard")
  seeIfEnableDeleteButton() {
    this.isDeleteButtonDisabled = true
    if (this.selectedCard.length > 0) {
      this.isDeleteButtonDisabled = false
    }
  }

}
