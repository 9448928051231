






























import {Component, Vue} from "vue-property-decorator";
import KanadeUploadTab from "@/components/domains/upload/kanadeupload/KanadeUploadTab.vue";
import KanadeDeleteTab from "@/components/domains/upload/kanadeupload/KanadeDeleteTab.vue";

@Component({
  components: {KanadeDeleteTab, KanadeUploadTab}
})
export default class KanadeUpload extends Vue {
  name = "KanadeUpload";
  tab: any = '';
}
