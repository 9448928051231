

































































import {Component, Vue, Watch} from "vue-property-decorator";
import ChooseCustomerDialog from "@/components/domains/ChooseCustomerDialog.vue";
import CustomerDisplayTable from "@/components/domains/CustomerDisplayTable.vue";
import {CalteActionTypes, CalteGetterTypes, CalteMutationTypes} from "@/store/calte/types";
import CreateCalteFormat from "@/components/domains/calte/pic/CreateCalteFormat.vue";
import CalteForm from "@/components/domains/calte/pic/CalteForm.vue";
import CalteFormat from "@/components/domains/calte/calteFormat";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import CalteRecord from "@/components/domains/calte/calteRecord";
import CalteList from "@/components/domains/calte/pic/CalteList.vue";
import CustomerSearchTable from "@/components/infrastructures/molecules/CustomerSearchTable.vue";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";

class CustomerSearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(`pic/calte/customers?condition=${condition}`);
    return response.data.users;
  }
}

@Component({
  components: {
    CustomerSearchTable,
    CalteList,
    AIEstheDetectFaceResultCard,
    CalteForm, CreateCalteFormat, CustomerDisplayTable, ChooseCustomerDialog
  }
})
export default class CalteManagement extends Vue {

  name = "CalteManagement";
  selectedCustomers = [];
  selectedCustomer: {} = {name: null};
  calteFormat: CalteFormat = new CalteFormat();
  aiEstheResult: {} = {};
  tab: any = 0;

  customerSearchAction = new CustomerSearchAction();

  // Dialog
  dialog = false;
  overlay = false;
  error = false;

  onDecideAIEstheResultAction = (aiEstheResult: any) => {
    httpResource.get(`/aiesthe/detect-result?detectId=${aiEstheResult.id}`)
        .then((response: any) => {
          this.setAiEstheResult(response.data);
        });
  }

  calteRecordSubmitAction = (calteRecord: CalteRecord) => {
    this.decide();
    this.$store.commit(CalteMutationTypes.setCalteRecord, calteRecord);
    this.$store.dispatch(CalteActionTypes.registerCalteRecord)
        .then((response: any) => {
          // NOP
        })
        .catch((e: any) => {
          this.settingError();
        })
        .finally(() => {
          this.openDialog();
        })
  }

  mounted() {
    this.updateCustomer();
    this.aiEstheResult = this.$store.getters[CalteGetterTypes.getAIEstheFaceDetectResult];
  }

  updateCustomer() {
    this.aiEstheResult = {};
    this.$store.commit(CalteMutationTypes.setAIEstheFaceDetectResult, this.aiEstheResult);
    this.selectedCustomer = this.$store.getters[CalteGetterTypes.getSelectedCustomer];
  }

  decide(): void {
    this.dialog = false;
    this.overlay = false;
    this.error = false;
  }

  settingError(): void {
    this.error = true;
  }

  openDialog(): void {
    this.dialog = true;
    this.overlay = true;
  }

  setAiEstheResult(result: any) {
    this.aiEstheResult = result;
    this.$store.commit(CalteMutationTypes.setAIEstheFaceDetectResult, this.aiEstheResult);
  }

  @Watch("selectedCustomers")
  selected() {
    if (this.selectedCustomers.length === 0) {
      this.selectedCustomer = {name: null};
      return;
    }
    this.selectedCustomer = this.selectedCustomers[0];
    this.$store.commit(CalteMutationTypes.setSelectedCustomer, this.selectedCustomer);
    this.updateCustomer();
  }

  @Watch("tab")
  watchTab() {
    this.selectedCustomer = this.$store.getters[CalteGetterTypes.getSelectedCustomer];
  }

  get getCalteId(): string {
    this.calteFormat = this.$store.getters[CalteGetterTypes.getCalteFormat];
    return this.calteFormat.calteId;
  }
}

