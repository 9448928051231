import {Module} from "vuex";
import {RootState} from "@/store/type";
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {CalteState} from "@/store/calte/types";
import CalteFormat from "@/components/domains/calte/calteFormat";
import CalteRecord from "@/components/domains/calte/calteRecord";

const state: CalteState = {
    selectedCustomer: {},
    calteFormat: new CalteFormat,
    calteRecord: new CalteRecord('', '', []),
    aiEstheFaceDetectResult: {}
}

export const editCalte: Module<CalteState, RootState> = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
