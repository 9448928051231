













import {Component, Vue} from "vue-property-decorator";
import {AuthActionTypes} from "@/store/auth/types";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import CategoryCard from "@/components/domains/esthe/soundesthe/CategoryCard.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";

@Component({
  components: {HoverLinkCard, CategoryCard, MyPageContentTemplate, LoggedInTemplate}
})
export default class Authorization extends Vue {

  public name = "Authorization";

  private isLoaded = false;
  private isPIC = false;
  private isAdmin = false;

  created() {

    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_PERSON_IN_CHARGE"})
        .then((hasRole: boolean) => {
          this.isPIC = hasRole;
          this.isLoaded = true;
        });

    this.$store.dispatch(AuthActionTypes.hasRole, {"role": "ROLE_ADMIN"})
        .then((hasRole: boolean) => {
          this.isAdmin = hasRole;
          this.isLoaded = true;
        });
  }
}
