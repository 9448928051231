
































import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import {AIEstheGetterTypes} from "@/store/aiesthe/types";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import AIEstheComparingHistory from "@/components/domains/esthe/aiesthe/history/AIEstheComparingHistory.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";

@Component({
  components: {HoverLinkCard, SubBtn, AIEstheComparingHistory, PrimaryBtn, KawaiiButton, AIEstheDetectFaceResultCard}
})
export default class ResultStep extends Step {

  name = "ResultStep";
  showOldestNewestComparing = false;
  result = {};

  getStepTitle(): string {
    return "AIエステ.顔診断エステ.結果確認";
  }

  getStepSubTitle(): string {
    return "";
  }

  getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    this.result = this.$store.getters[AIEstheGetterTypes.getDetectFaceResult];
  }

  clickFinished() {
    this.$router.push('/mypage/aiesthe');
  }
}
