import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {DmvGetterTypes, DmvState} from "@/store/dmv/types";

const getters: GetterTree<DmvState, RootState> = {
    [DmvGetterTypes.getSelectedWaves]: (state: DmvState): {} => {
        return state.subWaves;
    },
    [DmvGetterTypes.getPlayingMain]: (state: DmvState): {} => {
        return state.playingMain;
    }
}

export default getters;
