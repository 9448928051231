












import {Component, Vue} from "vue-property-decorator";
import AgreementService from "@/components/domains/agreement/agreementService";
import MarkdownViewer from "@/components/infrastructures/atoms/MarkdownViewer.vue";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, HomeTemplate, MarkdownViewer}
})
export default class PrivacyPolicyPage extends Vue {
  name = "PrivacyPolicyPage";

  markdown = '';

  get getMarkdown() {
    return this.markdown
  }

  mounted() {
    AgreementService.privacyPolicy()
        .then(obj => {
          this.markdown = obj.data;
        });
  }
}
