import MentalCheckQuestionResult
  from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckQuestionResult";

export default class MentalCheckMessageSelector {

  public static selectMessage(mentalCheckQuestionResult: number): string {
    switch (mentalCheckQuestionResult) {
      case MentalCheckQuestionResult.HEAD: {
        return '座って Dr.AI を頭の頂点にあてる';
      }
      case MentalCheckQuestionResult.EYES: {
        return '座る、または、仰向けで Dr.AI を額にあてる';
      }
      case MentalCheckQuestionResult.NECK: {
        return '座る、または、うつ伏せで Dr.AI を後頭部にあてる';
      }
      case MentalCheckQuestionResult.IN_STOMACH: {
        return '仰向けで Dr.AI を胃の上にあてる';
      }
      case MentalCheckQuestionResult.SURFACE_STOMACH: {
        return '仰向けで Dr.AI をおへその上にあてる';
      }
      case MentalCheckQuestionResult.LEGS: {
        return '仰向けで気になる側の足の付け根に Dr.AI をあてる';
      }
      case MentalCheckQuestionResult.KNEES: {
        return '仰向けで、そろえた両膝の上に Dr.AI をあてる';
      }
      case MentalCheckQuestionResult.HEART: {
        return '仰向けで胸の上に Dr.AI をあてる';
      }
      case MentalCheckQuestionResult.NOTHING: {
        return '仰向けでお腹の上に Dr.AI をあてる';
      }
    }
    return '';
  }
}
