
































import {Component, Vue} from "vue-property-decorator";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, StopWatchSoundPlayer: DMVPlayer, KawaiiButton, VerticalSlotContents}
})
export default class KanadeRandomPlan extends Vue {
  name = "KanadeRandomPlan";
  soundId = '';
  soundSrc = '';
  soundTitle = '';
  soundGenre = '';
  player = '';
  tags = [];
  playing = false;
  playingDateTime = new StopWatchDateTime();

  beforeMount() {

    httpResource.get('/kanade/random')
        .then((response: any) => {
          const data = response.data;
          this.soundId = data.id;
          this.soundSrc = data.soundSrc;
          this.soundTitle = data.title;
          this.soundGenre = data.genre;
          this.player = data.player;
          this.tags = data.tags;
        })
        .catch((error: any) => {
          alert('振動選択に失敗しました。');
        });
  }

  startStopListener(playing: boolean) {
    this.playing = playing;
  }

  receiveDateTimeOnStopped(id: string, dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
  }

  finish() {

    const request = {
      dmvTypeCode: "03",
      planCode: Plan.RANDOM.code,
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/kanade/");
  }

  get getSoundSrc() {
    return this.soundSrc;
  }
}
