

















import {Component} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import {AccountService} from "@/components/domains/account/AccountService";

@Component({
  components: {KawaiiButton, KawaiiDialog}
})
export default class ForgotPasswordConfirmMailForm extends Step {

  name = "ForgotPasswordConfirmMailForm";

  // dialog
  dialog = false;
  message = "";

  // mail
  sent = false;

  mailField: FieldData = new FieldData()
      .withId("mailAddress")
      .withRequires(true)
      .withType("text_field")
      .withLabel("メールアドレス");

  public getStepTitle(): string {
    return "パスワードリセット案内メールの送信";
  }

  public getStepSubTitle(): string {
    return "";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    const path = this.$route.params.path;
    if (path !== undefined && path !== '') {
      this.next();
    }
  }

  validateSentMail() {

    this.mailField.error = false;
    this.mailField.errorMessage = "";

    const mail = this.mailField.value.trim();

    if (mail === '') {
      this.mailField.error = true;
      this.mailField.errorMessage = "入力してください。";
      return false;
    }

    return true;
  }

  sentMail() {

    if (!this.validateSentMail()) {
      return;
    }

    const mail = this.mailField.value.trim();
    AccountService.forgotPasswordIssueConfirmMailTempUrl(mail)
        .then(() => {
          this.message = "パスワードリセット案内のメールを送信しました。"
          this.sent = true;
        })
        .catch((error: any) => {
          this.message = error.response.data.message;
        })
        .finally(() => {
          this.dialog = true;
        });
  }
}
