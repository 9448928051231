import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AiChatService {

    public static async conversationId() {
        return (await httpResource.post('/v2/ai-chat/conversation')).data;
    }

    public static async list(conversationId: string) {
        return (await httpResource.post(`/v2/ai-chat/list?conversationId=${conversationId}`)).data;
    }

    public static async chat(conversationId: string, message: string) {
        return (await httpResource.post(`/v2/ai-chat/chat?conversationId=${conversationId}&message=${message}`)).data;
    }
}