






































































import FieldData from "@/components/infrastructures/functions/fieldData";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class SoundUploadTab extends Vue {

  name = "SoundUploadTab";

  // dialog
  dialog = false;
  dialogTitle = "";
  dialogMessage = "";

  // register
  selectedDMVName = "";
  selectedDMVBin = "";
  disabledUpload = true;

  // field
  tagPool: {}[] = [];
  selectedTags: {}[] = [];

  // Chase Upload Mode
  chaseUpload = false;
  awsCommand = "";

  txtDMVName = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("タイトル");
  slctGenre = new FieldData()
      .withType("select")
      .withRequires(true);
  slctPurpose = new FieldData()
      .withType("select")
      .withRequires(true);
  slctCountry = new FieldData()
      .withType("select")
      .withRequires(true);
  txtPlace = new FieldData()
      .withType("text_field")
      .withRequires(false)
      .withLabel("音の場所");
  btnSelectedDMV = new FieldData()
      .withType("button")
      .withClickAction(this.openFileSelector)
      .withLabel("DMV 選択");

  public getRequestDataWith(dmv: string) {
    const data = this.getRequestData;
    data.dmv = dmv;
    return data;
  }

  protected get getRequestData() {
    return {
      planCode: "02",
      title: this.txtDMVName.value,
      genreId: this.slctGenre.value,
      countryId: this.slctCountry.value,
      place: this.txtPlace.value,
      tagIds: this.selectedTags.map((t: any) => t.value),
      fileName: this.selectedDMVName,
      purposeDetailId: this.slctPurpose.value,
      dmv: this.selectedDMVBin
    };
  }

  mounted() {
    this.fetchMaster();
  }

  fetchMaster() {

    httpResource.get("/api/sound-dmv/sound-dmv-mst")
        .then((response: any) => {
          const mst = response.data;
          this.slctGenre.withSelection(mst.genres);
          this.slctCountry.withSelection(mst.countries);
          this.slctPurpose.withSelection(mst.purposes);
          this.tagPool = mst.tags;
        });
  }

  selectTag(tag: any) {
    const contains = this.selectedTags.indexOf(tag) !== -1;
    if (contains) {
      this.selectedTags = this.selectedTags.filter((n: any) => n.value !== tag.value);
    } else {
      this.selectedTags = this.selectedTags.concat(tag);
    }
  }

  tagColor(tag: {}) {
    const contains = this.selectedTags.indexOf(tag) !== -1;
    if (contains) {
      return "secondary";
    }
    return "default";
  }

  selected() {
    const file = (this.$refs.input as any).files[0];

    this.selectedDMVName = file.name;
    if (this.txtDMVName.value === "") {
      this.txtDMVName.value = this.selectedDMVName;
    }

    const reader = new FileReader();
    reader.onload = (evt: any) => {
      this.selectedDMVBin = evt.target.result;
    }
    reader.readAsDataURL(file)
  }

  openFileSelector() {
    (this.$refs.input as any).click();
  }

  get validate(): boolean {
    this.disabledUpload =
        this.selectedDMVBin === ""
        || this.txtDMVName.value === ""
        || this.selectedTags.length === 0
        || this.slctCountry.value === ""
        || this.slctGenre.value === ""
        || this.slctPurpose.value === "";

    return !this.disabledUpload;
  }

  async upload2() {

    if (!this.validate) {
      return;
    }

    const data = this.chaseUpload ? this.getRequestDataWith("") : this.getRequestData;

    // 分割アップロード
    // const dmvLength = data.dmv.length;
    // const partSize = 100000;
    // const roundSize = Math.round(dmvLength / partSize) + 1;
    // const splitSize = roundSize;
    //
    // httpResource.post(`/upload/part-upload-id?fileName=${data.fileName}&size=${splitSize}`)
    //     .then((response: any) => {
    //
    //       const uploadId = response.data;
    //
    //       const str = data.dmv;
    //       const size = 250000;
    //       const numChunks = Math.ceil(str.length / size);
    //       // const chunks = new Array(numChunks);
    //       for (let i = 0, x = 0; i < numChunks; ++i, x += size) {
    //         const chunk = str.substr(x, size);
    //         const requestData = this.getRequestDataWith(chunk);
    //         httpResource.post(`/upload/part-upload-sound-dmv?uploadId=${uploadId}&partNum=${i + 1}`, requestData)
    //       }
    //     });

    this.disabledUpload = true;

    httpResource.post("/upload/sound-dmv", data)
        .then((response: any) => {
          this.dialogMessage = "正常に登録できました。"
          if (this.chaseUpload) {
            const bucket = response.data.bucket;
            const key = response.data.key;
            this.awsCommand = this.createUploadAWSCommand(bucket, key, this.selectedDMVName);
          }
        })
        .catch((error: any) => {
          // console.log(error)
          this.dialogTitle = "登録に失敗しました。";
          // this.dialogMessage = error.data.message;
          this.dialogMessage = "登録に失敗しました。";
        })
        .finally(() => {
          this.dialog = true;
          this.validate;
        });
  }

  upload() {

    if (!this.validate) {
      return;
    }

    const data = this.chaseUpload ? this.getRequestDataWith("") : this.getRequestData

    this.disabledUpload = true;

    httpResource.post("/upload/sound-dmv", data)
        .then((response: any) => {
          this.dialogMessage = "正常に登録できました。"
          if (this.chaseUpload) {
            //
          }
        })
        .catch((error: any) => {
          this.dialogMessage = "登録に失敗しました。"
        })
        .finally(() => {
          this.dialog = true;
          this.validate;
        });
  }

  protected createUploadAWSCommand(bucketName: string, key: string, filePath: string) {
    return `aws s3 cp ${filePath} s3://${bucketName}/${key}`
  }

  @Watch("selectedDMVBin")
  watchSelectedDMV() {
    this.validate;
  }

  @Watch("txtDMVName.value")
  watchTxtDMVName() {
    this.validate;
  }

  @Watch("slctGenre.value")
  watchSlctGenre() {
    this.validate;
  }

  @Watch("slctCountry.value")
  watchSlctCountry() {
    this.validate;
  }

  @Watch("selectedTags")
  watchSelectedTags() {
    this.validate;
  }

  @Watch("slctPurpose.value")
  watchSlctPurpose() {
    this.validate;
  }
}
