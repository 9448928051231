


















import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class VisibleInvisibleFrame extends Vue {

  name = "VisibleInvisibleFrame";

  @Prop({default: '表示中'}) visibleName: string;
  @Prop({default: '非表示中'}) invisibleName: string;
  @Prop({default: true}) defaultVisible: boolean;

  private label!: string;
  private visible = true;

  beforeMount() {
    this.toggled();
    this.visible = this.defaultVisible;
  }

  @Watch("visible")
  toggled() {
    if (this.visible) {
      this.label = this.visibleName;
    } else {
      this.label = this.invisibleName;
    }
  }
}
