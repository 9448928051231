












import {Component, Vue} from "vue-property-decorator";

@Component
export default class HpSmallText extends Vue {
  name = "HpSmallText"
}
