














import {Component, Vue} from "vue-property-decorator";
import LoggedInHeader from "@/components/templates/loggedin/LoggedInHeader.vue";
import DefaultHeader from "@/components/templates/default/DefaultHeader.vue";
import DefaultFooter from "@/components/templates/default/DefaultFooter.vue";

@Component({
  components: {DefaultFooter, DefaultHeader, LoggedInHeader}
})
export default class DefaultTemplate extends Vue {
  name = "DefaultTemplate";

}
