import { render, staticRenderFns } from "./Under64Page.vue?vue&type=template&id=3aca0343&scoped=true&"
import script from "./Under64Page.vue?vue&type=script&lang=ts&"
export * from "./Under64Page.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aca0343",
  null
  
)

export default component.exports