import { render, staticRenderFns } from "./MarkdownViewer.vue?vue&type=template&id=340b62b0&scoped=true&"
import script from "./MarkdownViewer.vue?vue&type=script&lang=ts&"
export * from "./MarkdownViewer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340b62b0",
  null
  
)

export default component.exports