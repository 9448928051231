








































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import QuestionTreeData from "@/components/infrastructures/functions/question/questionTreeData";
import Answer from "@/components/infrastructures/functions/question/answer";
import Question from "@/components/infrastructures/functions/question/question";

@Component
export default class QuestionTree extends Vue {
  name = "QuestionTree";

  @VModel({default: []}) answers!: Array<Answer>;
  @Prop() questionTree!: QuestionTreeData;

  // Screen
  key = 0;

  ended = false;
  resultMessage = "";

  // Question
  question!: Question;
  qCnt = 0;

  beforeMount() {
    this.reset();
  }

  updateQuestion(answer: Answer) {
    this.answers.push(answer);
    this.qCnt++;
    if (this.questionTree.hasNextQuestion(answer.getId())) {
      this.question = this.questionTree.getQuestion(answer.getId());
    } else {
      this.ended = true;
      this.answered();
    }
    this.updateKey();
  }

  reset() {
    this.$emit("reset");
    this.question = this.questionTree.getFirstQuestion();
    this.ended = false;
    this.resultMessage = "";
    this.answers = [];
    this.qCnt = 0;
  }

  updateKey() {
    this.key++;
  }

  answered() {
    this.$emit("answered", this.answers);
  }
}
