




























































import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import EstheService from "@/components/domains/esthe/EstheMusicService";

@Component({
  components: {PrimaryBtn, KawaiiButton, KawaiiDialog}
})
export default class SoundTherapyDMVUploadTab extends Vue {
  name = "SoundTherapyDMVUploadTab";

  // dialog
  dialog = false;
  dialogMessage = "";

  // register
  selectedDMVName = "";
  selectedDMVBin = "";
  disabledUpload = true;

  slctGenre = new FieldData();

  txtDMVName = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("曲名");

  txtComposer = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("作曲家");

  txtEffects = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("美容効果(カンマ区切りで複数)");

  slctExpCluster = new FieldData()
      .withType("select")
      .withRequires(true)
      .withSelection([
        {text: "Type I", value: 1},
        {text: "Type II", value: 2},
        {text: "Type III", value: 3},
        {text: "Type IV", value: 4}
      ]);

  btnSelectedDMV = new FieldData()
      .withType("button")
      .withClickAction(this.openFileSelector)
      .withLabel("DMV 選択");

  bodyParts = [
    {text: '目元', value: 'eyes'},
    {text: '口元', value: 'mouth'}
  ];
  selectedBodyParts: string[] = [];

  clickBodyParts(item: { text: string; value: string }) {
    if (this.selectedBodyParts.indexOf(item.value) != -1) {
      return this.selectedBodyParts = this.selectedBodyParts.filter(s => s !== item.value);
    }
    this.selectedBodyParts.push(item.value);
  }

  getBodyPartChipColor(item: { text: string; value: string }) {
    if (this.selectedBodyParts.indexOf(item.value) != -1) {
      return 'secondary';
    }
    return '';
  }

  beforeMount() {
    this.fetchGenres();
  }

  fetchGenres() {
    EstheService.fetchMusicGenres().then((response) => {
      this.slctGenre.withType("select")
          .withRequires(true)
          .withSelection(response.data);
    });
  }

  selected() {
    const file = (this.$refs.input as any).files[0];

    this.selectedDMVName = file.name;
    if (this.txtDMVName.value === "") {
      this.txtDMVName.value = this.selectedDMVName;
    }

    const reader = new FileReader();
    reader.onload = (evt: any) => {
      this.selectedDMVBin = evt.target.result;
    }
    reader.readAsDataURL(file)
  }

  openFileSelector() {
    (this.$refs.input as any).click();
  }

  validate(): boolean {
    this.disabledUpload =
        this.selectedDMVBin === ""
        || this.txtDMVName.value === ""
        || this.txtComposer.value === ""
        || this.txtEffects.value === ""
        || this.slctGenre.value === ""
        || this.slctExpCluster.value === ""
        || this.selectedBodyParts.length === 0;

    return !this.disabledUpload;
  }

  upload() {

    if (!this.validate()) {
      return;
    }

    const data = {
      title: this.txtDMVName.value,
      genre: this.slctGenre.value,
      faceExpCluster: this.slctExpCluster.value,
      composer: this.txtComposer.value,
      effects: this.txtEffects.value,
      fileName: this.selectedDMVName,
      dmv: this.selectedDMVBin,
      bodyParts: this.selectedBodyParts
    };

    this.disabledUpload = true;

    httpResource.post("/upload/sound-therapy-dmv", data)
        .then((response: any) => {
          this.dialogMessage = "正常に登録できました。"
        })
        .catch((error: any) => {
          this.dialogMessage = "登録に失敗しました。"
        })
        .finally(() => {
          this.dialog = true;
          this.validate();
        });

  }

  @Watch("selectedDMVBin")
  watchSelectedDMV() {
    this.validate();
  }

  @Watch("slctExpCluster.value")
  watchSlctExpCluster() {
    this.validate();
  }

  @Watch("slctEstheMinutesCourse.value")
  watchSlctEstheMinutesCourse() {
    this.validate();
  }
}
