import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class SupportService {

    public static async fetchChatInquiryTypes() {
        return (await httpResource.get('/v2/support/chat/inquiry/types')).data;
    }

    public static async fetchInquiries() {
        return (await httpResource.get('/v2/support/chat/inquiry/inquiries')).data;
    }

    public static async fetchChats(inquiryId: string) {
        return (await httpResource.get(`/v2/support/chat/inquiry/chats?inquiryId=${inquiryId}`)).data;
    }

    public static async chat(inquiryId: string, message: string, subject = "") {
        const data = {
            inquiryId: inquiryId,
            subject: subject,
            text: message
        }
        return (await httpResource.post(`/v2/support/chat/inquiry/chat`, data)).data;
    }

    public static async read(inquiryId: string) {
        return (await httpResource.post(`/v2/support/chat/inquiry/read?inquiryId=${inquiryId}`)).data;
    }

    public static async newChat(detailTypeId: string, subject: string, text: string) {
        const data = {
            detailTypeId: detailTypeId,
            subject: subject,
            text: text
        };
        return (await httpResource.post('/v2/support/chat/inquiry/new-chat', data)).data;
    }
}