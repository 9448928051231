<template>
  <v-btn></v-btn>
</template>

<script>
export default {
  name: "DecideBtn"
}
</script>

<style scoped>

</style>