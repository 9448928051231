import {MutationTree} from "vuex";
import {PurchaseMutationTypes, PurchaseState} from "@/store/product/purchase/types";


const mutations: MutationTree<PurchaseState> = {
    [PurchaseMutationTypes.clear]: (state, productId: string) => {
        state.productId = "";
        state.creditCardId = "";
        state.users = [];
    },
    [PurchaseMutationTypes.setProductId]: (state, productId: string) => {
        state.productId = productId;
    },
    [PurchaseMutationTypes.setCreditCardId]: (state, creditCardId: string) => {
        state.creditCardId = creditCardId;
    },
    [PurchaseMutationTypes.setDeliveryAddressId]: (state, addressId: string) => {
        state.addressId = addressId;
    },
    [PurchaseMutationTypes.setUsers]: (state, users: []) => {
        state.users = users;
    }
}

export default mutations;
