
export interface PageSizeState {
    scale: number;
}

export enum PageSizeGetterTypes {
    getScale = "PageSizeGetScale"
}

export enum PageSizeMutationTypes {
    setScale = "PageSizeSetScale"
}
