import { render, staticRenderFns } from "./MonthlyActivityChart.vue?vue&type=template&id=adff8f4e&scoped=true&"
import script from "./MonthlyActivityChart.vue?vue&type=script&lang=ts&"
export * from "./MonthlyActivityChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adff8f4e",
  null
  
)

export default component.exports