import axios from "axios";
import store from "@/store";
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import router from "@/router";

const isHandlerEnabled = (config: any) => {
    // eslint-disable-next-line no-prototype-builtins
    return !(config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled);
};

// axiosInstance.get('/v2/api-endpoint', { handlerEnabled: false })

const instance = axios.create({
    baseURL: process.env.VUE_APP_RESOURCE_SERVER,
    withCredentials: true,
    timeout: 0
});

// instance.defaults.withCredentials = true;
instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

const requestHandler = (request: any) => {
    if (isHandlerEnabled(request)) {
        // NOP
        // console.log("Request Interceptor", request);
    }
    return request;
};

const errorHandler = (error: any) => {
    if (isHandlerEnabled(error.config)) {

        // console.log("Error Interceptor", error);

        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {

                if (store.getters[AuthGetterTypes.getIsAuthenticated]) {
                    store.dispatch(AuthActionTypes.logout);
                    alert('認証に失敗したのでログアウトしました。\n再度ログインしてください。');
                }

                if (router.currentRoute.path != '/login') {
                    router.push('/login');
                }
            }
        }
    }
    return Promise.reject({...error});
};

const successHandler = (response: any) => {
    if (isHandlerEnabled(response.config)) {
        // NOP
        // console.log("Response Interceptor", response);
    }
    return response;
};

instance.interceptors.request.use((request: any) => requestHandler(request));

instance.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
);

export default instance;