



























































import {Component, Prop, Vue} from "vue-property-decorator";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn}
})
export default class ProductCard extends Vue {

  public name = "ProductCard";
  @Prop({default: ''}) imgUrl: string;
  @Prop() product: { id: string; displayName: string; functions: string[]; price: string; description: string; statusCode: string };
  show = false

  get isPurchasable() {
    return this.product.statusCode === '00' ||
        this.product.statusCode === '10' ||
        this.product.statusCode === '20';
  }

  get getPurchaseMessage() {
    switch (this.product.statusCode) {
      case '00':
        return '契約する';
      // case '10':
      //   return 'アップグレード';
      // case '20':
      //   return 'ダウングレード';
      case '90':
        return '既に契約中です';
      case '91':
        return 'レンタル品配送待ち';
      case '92':
        return 'レンタル品返却待ち';
      default:
        return '購入可能';
    }
  }
}
