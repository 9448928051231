







































import {Component, Vue} from "vue-property-decorator";
import {AIEstheMutationTypes} from "@/store/aiesthe/types";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import MentalCheckInductionHoverLink from "@/components/domains/mentalcheck/MentalCheckInductionHoverLink.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import ProductCard from "@/components/domains/products/ProductCard.vue";
import EstheService from "@/components/domains/esthe/EstheService";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import {DmvMutationTypes} from "@/store/dmv/types";

@Component({
  components: {ProductLineupDialog, ProductCard, MyPageContentTemplate, MentalCheckInductionHoverLink, HoverLinkCard}
})
export default class AIEsthePlanSelectionPage extends Vue {

  name = "AIEsthePlanSelectionPage";
  overlay = true;

  created() {
    this.$store.commit(AIEstheMutationTypes.setCourse, 0);
  }

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  beforeMount() {

    // 音楽療法権限確認
    EstheService.checkAvailableAIEsthe()
        .then(res => {
          if (res.data === 'E0000') {
            // NOP
          } else {
            this.funcName = 'AI エステ';
            this.products = res.data.products;
            this.productsDialog = true;
          }
        });
  }

  mounted() {
    this.$store.commit(DmvMutationTypes.setSelectedWave, []);
  }

  closeProductDialog() {
    this.$router.back();
  }
}
