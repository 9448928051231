

















import {Component, Watch} from "vue-property-decorator";
import AddressForm from "@/components/domains/account/profile/address/AddressForm.vue";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";

@Component({
  components: {AddressForm}
})
export default class DeliveryAddressForm extends AddressForm<DeliveryAddress> {

  name = "DeliveryAddressForm";

  public txtLastName = "";
  public txtMiddleName = "";
  public txtFirstName = "";
  public txtPhoneNumber = "";
  public regulation = false;

  mounted() {
    this.initializeValue();
  }

  public initializeValue() {

    if (this.modelVal.lastName !== '') {
      this.txtLastName = this.modelVal.lastName;
    }
    if (this.modelVal.middleName !== '') {
      this.txtMiddleName = this.modelVal.middleName;
    }
    if (this.modelVal.firstName !== '') {
      this.txtFirstName = this.modelVal.firstName;
    }
    if (this.modelVal.phoneNumber !== '') {
      this.txtPhoneNumber = this.modelVal.phoneNumber;
    }
    this.regulation = this.modelVal.regulation;
  }

  public reset(): void {

    super.reset();

    this.txtPhoneNumber = "";
    this.txtFirstName = "";
    this.txtMiddleName = "";
    this.txtLastName = "";
    this.regulation = false;
  }

  @Watch('txtLastName')
  public updateLastName() {
    this.modelVal.lastName = this.txtLastName;
  }

  @Watch('txtMiddleName')
  public updateMiddleName() {
    this.modelVal.middleName = this.txtMiddleName;
  }

  @Watch('txtFirstName')
  public updateFirstName() {
    this.modelVal.firstName = this.txtFirstName;
  }

  @Watch('txtPhoneNumber')
  public updatePhoneNumber() {
    this.modelVal.phoneNumber = this.txtPhoneNumber;
  }

  @Watch('regulation')
  public updateReguration() {
    this.modelVal.regulation = this.regulation;
  }
}
