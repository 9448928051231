











import {Component, Vue} from "vue-property-decorator";
import LoginForm from "@/components/domains/account/login/LoginForm.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import authService from "@/components/domains/authorization/authService";

@Component({
  components: {DefaultTemplate, LoginForm, GeneralField}
})
export default class Login extends Vue {
  name = 'Login';

  mounted() {
    authService.authMe().then(() => {

      if (authService.isLoggedIn()) {
        this.$router.push("/mypage");
      }
    })
  }
}
