















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AiChatService from "@/components/domains/ai_chat/aiChatService";

@Component
export default class AiChatForm extends Vue {
  name = "AiChatForm";
  @Prop({default: []}) chats!: {}[];

  conversationId = '';

  message = '';

  active = false;

  intervalId = 0;

  mounted() {
    AiChatService.conversationId().then((data) => {
      this.conversationId = data;
    });
  }

  beforeDestroy() {
    clearInterval(this.intervalId);
  }

  chat() {

    if (this.active) {
      alert("もう少々お待ちください。")
    }

    this.active = true;
    this.chats.push({text: this.message, username: "あなた", me: true})
    AiChatService.chat(this.conversationId, this.message).finally(() => {
      this.fetchList();
    }).finally(() => {
      this.active = false;
    });

    this.message = "";
  }

  @Watch('conversationId')
  fetchList() {
    AiChatService.list(this.conversationId).then((data) => {
      this.chats = data;
    })
  }
}
