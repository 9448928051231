





































import {Component, Prop, Vue} from "vue-property-decorator";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";

@Component({
  components: {
    SmartDMVPlayer,
  }
})
export default class KanadeItemCard extends Vue {

  name = "KanadeItemCard";

  @Prop() item!: {
    id: string;
    title: string;
    genre: string;
    country: string;
    tags: string[];
    path: string;
    player: string;
  };

  @Prop({
    default: () => {
      /* NOP */
    }
  }) startedAction: Function;

  @Prop({
    default: () => {
      /* NOP */
    }
  }) stoppedAction: Function;

  @Prop({default: false}) selectMode: boolean;

  receiveDateTimeOnStarted(id: string, dateTime: StopWatchDateTime) {
    this.startedAction();
  }

  receiveDateTimeOnStopped(id: string, dateTime: StopWatchDateTime) {

    this.stoppedAction();

    const request = {
      dmvTypeCode: "03",
      planCode: Plan.RANDOM.code,
      vibrationId: this.item.id,
      startDateTime: dateTime.formattedStartDateTime,
      endDateTime: dateTime.formattedEndDateTime,
      totalPlayingTime: dateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);
  }
}
