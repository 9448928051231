import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {PurchaseGetterTypes, PurchaseState} from "@/store/product/purchase/types";

const getters: GetterTree<PurchaseState, RootState> = {
    [PurchaseGetterTypes.getProductId]: (state: PurchaseState): string => {
        return state.productId
    },
    [PurchaseGetterTypes.getCreditCardId]: (state: PurchaseState): string => {
        return state.creditCardId
    },
    [PurchaseGetterTypes.getDeliveryAddressId]: (state: PurchaseState): string => {
        return state.addressId
    },
    [PurchaseGetterTypes.getUsers]: (state: PurchaseState): [] => {
        return state.users
    }
}

export default getters;
