import {Module} from "vuex";
import {RootState} from "@/store/type";
import getters from './getters';
import mutations from './mutations';
import {AccountTransferState} from "@/store/account/transfer/types";
import {User} from "@/components/domains/authorization/user";
import {Agency} from "@/components/domains/authorization/agency";

const state: AccountTransferState = {
    user: new User(),
    targetAgency: new Agency(),
    targetRoles: []
}

export const accountTransfer: Module<AccountTransferState, RootState> = {
    namespaced: false,
    state,
    getters,
    mutations
}
