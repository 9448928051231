






































import Vue from "vue";
import {Component} from "vue-property-decorator";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import AiChatForm from "@/components/domains/ai_chat/AiChatForm.vue";

@Component({
  components: {AiChatForm, KawaiiDialog}
})
export default class AiChatButton extends Vue {
  name = "AiChatButton"
  dialog = false;
}
