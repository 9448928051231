































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DMVUploadTab from "@/components/domains/upload/dmvupload/DMVUploadTab.vue";
import DMVDeleteTab from "@/components/domains/upload/dmvupload/DMVDeleteTab.vue";
import SoundUploadTab from "@/components/domains/upload/soundupload/SoundUploadTab.vue";
import SoundDeleteTab from "@/components/domains/upload/soundupload/SoundDeleteTab.vue";

@Component({
  components: {SoundDeleteTab, SoundUploadTab, DMVDeleteTab, DMVUploadTab, KawaiiButton}
})
export default class SoundUpload extends Vue {

  name = "SoundUpload";
  tab: any = '';
}
