export class QuestionnaireSelection {

    private static readonly _values: QuestionnaireSelection[] = []

    static readonly APPLICABLE = new QuestionnaireSelection("あてはまる", 0);
    static readonly SOMETIMES = new QuestionnaireSelection("ときどき", 1);
    static readonly NOT_APPLICABLE = new QuestionnaireSelection("ない", 2);
    static readonly UNANSWERED = new QuestionnaireSelection("未回答", 99);

    private constructor(
        readonly display: string,
        readonly value: number) {
        QuestionnaireSelection._values.push(this);
    }

    static fromValue(value: number): QuestionnaireSelection | undefined {
        return QuestionnaireSelection._values.find(x => x.value === value);
    }

    static values(): QuestionnaireSelection[] {
        return QuestionnaireSelection._values
    }
}