import {Vue} from "vue-property-decorator";

export default class WindowSize {

    public static isSmartphoneSize(component: Vue): boolean {
        return component.$vuetify.breakpoint.smAndDown;
    }

    public static isPCSize(component: Vue): boolean {
        return component.$vuetify.breakpoint.mdAndUp;
    }
}