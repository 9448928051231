










import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class InnerScrollCard extends Vue {
  name = "InnerScrollCard";

  @Prop({default: '100%'}) height: string;
  @Prop({default: '100%'}) width: string;
}
