











import {Component, Vue} from "vue-property-decorator";
import ConfirmMailForm from "@/components/domains/account/register/general/steps/ConfirmMailForm.vue";
import PersonalRegisterForm from "@/components/domains/account/register/general/steps/PersonalRegisterForm.vue";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import AgreementsStore from "@/components/domains/account/register/general/agreementsStore";

@Component({
  components: {ContentTitle, DefaultTemplate}
})
export default class GeneralRegisterPage extends Vue {
  name = "GeneralRegisterPage";

  steps: Step[] = [];
  createdStep = false;

  mounted() {
    AgreementsStore.reset();
    this.steps = [
      // new AgreementsForm(),
      new ConfirmMailForm(),
      new PersonalRegisterForm()
    ];
    this.createdStep = true;
  }
}
