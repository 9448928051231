
















import {Component, Prop, Vue} from "vue-property-decorator";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

export class GrantAuthoritySearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {

    const request = {
      authorities: ['ai_esthe'],
      condition: condition
    };

    const response = await httpResource.post(`grant/search-users`, request);
    return response.data;
  }
}

@Component
export default class GrantAuthoritySearchTable extends Vue {

  name = "GrantAuthoritySearchTable";

  private value!: [];

  @Prop({default: () => new GrantAuthoritySearchAction()}) searchAuthorityAction: any;
  @Prop({default: 5}) itemsPerPage: number;

  // local args
  headers = [
    {text: "権限名", value: "id"},
  ];

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  changeSelection(customers: any) {
    this.$emit('changeSelection', customers)
  }
}
