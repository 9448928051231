import httpResource from "@/components/infrastructures/functions/http/httpResource";

export class AccountService {

    // TODO ForgotPassword に移動
    public static async forgotPasswordIssueConfirmMailTempUrl(mail: string) {
        return (await httpResource.post(`/account/forgot-password/issue-confirm-mail?email=${mail}`)).data;
    }

    public static async issueConfirmMailTempUrl(mail: string, productId: string) {
        return (await httpResource.post(`/account/issue-confirm-mail?email=${mail}&productId=${productId}`)).data;
    }

    public static async confirmTempUrl(mail: string) {
        return (await httpResource.post(`/account/confirm-temp-url`))
    }

    public static searchUsers(page: number, itemsPerPage: number, condition: string, roles: string[]) {
        return httpResource.get(`account/all-users?page=${page - 1}&size=${itemsPerPage}&condition=${condition}&roles=${roles}`);
    }

    public static searchCustomers(page: number, itemsPerPage: number, condition: string) {
        return this.searchUsers(page, itemsPerPage, condition, ["customer"]);
    }

    public static searchPICs(page: number, itemsPerPage: number, condition: string) {
        return this.searchUsers(page, itemsPerPage, condition, ["pic"]);
    }
}
