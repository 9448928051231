import httpResource from "@/components/infrastructures/functions/http/httpResource";
import Address from "@/components/domains/account/profile/address/address";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";

export default class AddressService {

    public static async registerDelivery(address: DeliveryAddress) {
        return (await httpResource.post('v2/address/register/delivery', address)).data;
    }

    public static async updateDelivery(address: DeliveryAddress) {
        return (await httpResource.post('v2/address/update/delivery', address)).data;
    }

    public static async deleteDelivery(address: DeliveryAddress) {
        return (await httpResource.post(`v2/address/delete/delivery?id=${address.deliveryId}`)).data;
    }

    public static async fetchPrefectures() {
        return (await httpResource.get(`v2/address/prefectures`)).data;
    }

    public static async fetchMunicipalities(prefCode: string) {
        return (await httpResource.get(`v2/address/municipalities?prefectureCode=${prefCode}`)).data;
    }

    public static async fetchDeliveryAddresses() {
        return (await httpResource.get(`v2/address/deliveries`)).data;
    }

    public static async searchFromPostcode(postcode: string): Promise<Address> {
        return (await httpResource.get(`v2/address/search?postcode=${postcode}`)).data as Address;
    }

    public static async getDeliveryAddress(id: string): Promise<DeliveryAddress> {
        return (await httpResource.get(`v2/address/delivery?id=${id}`)).data as DeliveryAddress;
    }
}