


















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DetectFaceResultCard extends Vue {
  @Prop() title!: string;
  @Prop() image!: string;
  @Prop() result!: any;
  @Prop() latestUpdate!: string;

  @Watch('image')
  onImageChanged() {
    this.$nextTick(() => {
      this.drawImageWithGrid();
    });
  }

  mounted() {
    this.drawImageWithGrid();
  }

  private drawImageWithGrid() {
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      // Canvasのサイズを画像のアスペクト比に合わせて調整
      const maxWidth = canvas.parentElement ? canvas.parentElement.offsetWidth : window.innerWidth;
      const maxHeight = window.innerHeight;
      const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        this.drawGrid(ctx, canvas.width, canvas.height);
      }
    };

    img.src = 'data:image/png;base64,' + this.image;
  }

  private drawGrid(ctx: CanvasRenderingContext2D, width: number, height: number) {
    const gridSize = 40; // グリッドのサイズ（ピクセル）
    ctx.beginPath();
    for (let x = 0; x <= width; x += gridSize) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, height);
    }
    for (let y = 0; y <= height; y += gridSize) {
      ctx.moveTo(0, y);
      ctx.lineTo(width, y);
    }
    ctx.strokeStyle = 'rgba(200, 200, 200, 0.9)';
    ctx.stroke();
  }

  private isShowableFaceAge(): boolean {
    return false;
  }

  private isShowableStress(): boolean {
    return this.result && this.result.emotions != null && this.result.emotions.emotionMap != null && this.result.emotions.emotionMap.HAPPY != null;
  }

  private get stressPercent(): number {
    const unhappy = (100 - this.result.emotions.emotionMap.HAPPY) * 0.7;
    const fear = this.result.emotions.emotionMap.FEAR;
    const sad = this.result.emotions.emotionMap.SAD;
    const angry = this.result.emotions.emotionMap.ANGRY;
    return Math.round((unhappy + fear + sad + angry) / 4);
  }
}
