import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import {RootState} from "@/store/type";
import {auth} from "@/store/auth";
import {aiesthe} from "@/store/aiesthe";
import {editCalte} from "@/store/calte";
import {purchase} from "@/store/product/purchase";
import {pagesize} from "@/store/pagesize";
import {accountTransfer} from "@/store/account/transfer";
import {dmv} from "@/store/dmv";
import {enterprise} from "@/store/enterprise";

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0',
    },
    modules: {
        enterprise,
        accountTransfer,
        auth,
        aiesthe,
        editCalte,
        purchase,
        pagesize,
        dmv
    },
};

export default new Vuex.Store<RootState>(store);

