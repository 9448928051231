<template>
  <div>
    <v-card align="center" elevation="0">
      <v-card-title>{{ $t('メディカルサウンド.目的で選ぶ') }}</v-card-title>
    </v-card>
    <v-card align="center" elevation="0">
      <div class="mb-5 mt-5">
        <v-toolbar-title>{{ soundTitle }}</v-toolbar-title>
      </div>
      <v-chip class="mx-1" color="success" text-color="black" small v-for="item in tags" :key="item">{{ item }}</v-chip>
    </v-card>
    <vertical-slot-contents v-if="soundSrc" :slots="2">
      <template v-slot:1>
        <v-container>
          <stop-watch-sound-player
              :sound-src="soundSrc"
              :start-stop-listener="startStopListener"
              :receive-date-time-on-stopped="receiveDateTimeOnStopped"
              :show-control-panel="true"/>
        </v-container>
      </template>
      <template v-slot:2>
        <v-card elevation="0" align="center">
          <!--          <kawaii-button color="grey" to="/mypage/soundesthe/">-->
          <!--            プラン選択に戻る-->
          <!--          </kawaii-button>-->
          <primary-btn :disabled="playing" @click="finish">
            {{ $t('終了する') }}
          </primary-btn>
        </v-card>
      </template>
    </vertical-slot-contents>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn";

@Component({
  components: {PrimaryBtn, KawaiiButton, StopWatchSoundPlayer: DMVPlayer}
})
export default class SoundEsthePurposePlaying extends Vue {

  name = "SoundEsthePurposePlaying";

  soundId = '';
  soundSrc = '';
  soundTitle = '';
  soundGenre = '';
  tags = [];
  playing = false;
  playingDateTime = new StopWatchDateTime();

  beforeMount() {

    const purposeDetailId = this.$route.params.id;

    httpResource.get(`/soundesthe/purpose-detail-random?detailId=${purposeDetailId}`)
        .then((response) => {
          const data = response.data;
          this.soundId = data.id;
          this.soundSrc = data.soundSrc;
          this.soundTitle = data.title;
          this.soundGenre = data.genre;
          this.tags = data.tags;
        })
        .catch(() => {
          alert('振動選択に失敗しました。');
        });
  }

  startStopListener(playing) {
    this.playing = playing;
  }

  receiveDateTimeOnStopped(id, dateTime) {
    this.playingDateTime = dateTime;
  }

  finish() {

    const request = {
      dmvTypeCode: "02",
      planCode: Plan.PURPOSE.code,
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/soundesthe/purpose");
  }

  get getSoundSrc() {
    return this.soundSrc;
  }
}
</script>

<style scoped>

</style>
