export default class FieldData {

    id = "";
    type = "";
    label = "";
    defaultDispValue = "";
    defaultInnerValue = "";
    verticalPriority = 1;
    // text: string; value: any;
    selection: {}[] = [];
    maxLength = 0;
    rangeFrom = 0;
    rangeTo = 0;
    requires = false;
    value: any = '';
    error = false;
    errorMessage = ''
    disabled = false;
    clickAction: Function = () => {
        // NOP
    };

    public getStringValue(): string {
        return "" + (this.value as string);
    }

    public withId(id: string): FieldData {
        this.id = id;
        return this;
    }

    public withType(type: string): FieldData {
        this.type = type;
        return this;
    }

    public withLabel(label: string): FieldData {
        this.label = label;
        return this;
    }

    public withDispValue(dispValue: string): FieldData {
        this.defaultDispValue = dispValue;
        return this;
    }

    public withInnerValue(innerValue: string): FieldData {
        this.defaultInnerValue = innerValue;
        return this;
    }

    public withVerticalPriority(priority: number): FieldData {
        this.verticalPriority = priority;
        return this;
    }

    public withSelection(selection: {}[]): FieldData {
        this.selection = selection;
        return this;
    }

    public withMaxLength(maxLength: number): FieldData {
        this.maxLength = maxLength;
        return this;
    }

    public withRangeFrom(rangeFrom: number): FieldData {
        this.rangeFrom = rangeFrom;
        return this;
    }

    public withRequires(requires: boolean): FieldData {
        this.requires = requires;
        return this;
    }

    public withClickAction(clickAction: Function): FieldData {
        this.clickAction = clickAction;
        return this;
    }

    public withValue(value: string): FieldData {
        this.value = value;
        return this;
    }

    public withError(error: boolean): FieldData {
        this.error = error;
        return this;
    }

    public withErrorMessage(errorMessage: string): FieldData {
        this.errorMessage = errorMessage;
        return this;
    }

    public withDisabled(disabled: boolean): FieldData {
        this.disabled = disabled;
        return this;
    }

    public resetError(): void {
        this.error = false;
        this.errorMessage = '';
    }
}
