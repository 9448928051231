export default class Profile {

    public birthYear = -1;
    public birthMonth = -1;
    public birthDay = -1;
    public gender = '';

    public existsBirthday(): boolean {
        return this.birthYear != -1 && this.birthMonth != -1 && this.birthDay != -1;
    }
}