









import {Component, Prop} from "vue-property-decorator";
import {VRadio} from "vuetify/lib";

@Component
export default class BigRadio extends VRadio {
  name = "BigRadio";

  @Prop() value!: number;
  @Prop() label!: string;
}
