























import {Component, Prop, Vue} from "vue-property-decorator";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import SupportService from "@/components/domains/support/supportService";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, HomeTemplate, ContentTitle}
})
export default class NewChatForm extends Vue {
  name = "NewChatForm";

  @Prop({
    default: () => {
      // NOP
    }
  }) afterSending: Function;

  // type
  types: { text: string; value: string; childItems: { text: string; value: string }[] }[] = [];
  selectedType = "";
  selectedDetailType = "";

  // subject
  subject = "";

  // text
  text = "";

  beforeCreate() {
    SupportService
        .fetchChatInquiryTypes()
        .then((types) => {
              this.types = types;
            }
        )
  }

  get isDisabledSending() {
    return this.text === '' || this.subject === '' || this.selectedDetailType == '';
  }

  private newChat() {
    SupportService
        .newChat(this.selectedDetailType, this.subject, this.text)
        .then(() => {
          this.selectedDetailType = "";
          this.subject = "";
          this.text = "";
          alert("送信に成功しました。")
        })
        .catch(() => {
          alert("入力不備があるためチャット送信に失敗しました。")
        })
        .finally(() => {
          this.afterSending();
        });
  }

  get getTypeDetails() {
    return this.types
        .filter(t => t.value === this.selectedType)
        .flatMap(t => t.childItems);
  }
}
