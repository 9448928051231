



























import {Component, Prop, Vue} from "vue-property-decorator";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";

@Component({
  components: {DescriptionText}
})
export default class HowToUseCard extends Vue {
  name = "HowToUseCard";

  @Prop({default: ''}) prefix!: string;
  @Prop({default: ''}) title!: string;
  @Prop({default: ''}) img: string;
  @Prop({default: '#EF8893'}) color: string;
}
