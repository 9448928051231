import {Module} from "vuex";
import {AuthState} from "@/store/auth/types";
import {RootState} from "@/store/type";
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import {User} from "@/components/domains/authorization/user";

const state: AuthState = {
    authenticated: false,
    currentUser: new User(),
    intervalName: '',
    myPageLinks: [],
}

export const auth: Module<AuthState, RootState> = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
