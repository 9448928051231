


















































































import {Component, Vue} from "vue-property-decorator";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle}
})
export default class IntroPapers extends Vue {
  name = "IntroPapers";

  currentPage = 1;
  pageCount = 0;

  nextPage() {
    this.currentPage < this.pageCount ? this.currentPage += 1 : this.currentPage = 1;
  }

  prevPage() {
    this.currentPage > 1 ? this.currentPage -= 1 : this.currentPage = this.pageCount;
  }
}
