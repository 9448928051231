

























import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import CreditCardRegister from "@/components/domains/payment/creditcard/CreditCardRegister.vue";
import CreditCardSearchTable from "@/components/domains/payment/creditcard/CreditCardSearchTable.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component({
  components: {CreditCardSearchTable, CreditCardRegister}
})
export default class CreditCardForm extends Vue {
  name = "CreditCardForm"

  @VModel() selectedCard: string[];

  // input card
  showInputCard = false;

  pulishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
  errorMessage = ''
  countCards = 0;
  tableKey = '';

  // select card
  @Prop({default: false}) showRemove: boolean;
  @Prop({default: true}) showSelect: boolean;

  beforeMount() {
    httpResource.get(`credit-card/count-all/`).then((response: any) => {
      this.countCards = response.data
      if (this.countCards == 0) {
        this.showInputCard = true;
      }
    });
  }

  afterRegistered(result: {response: any; hasError: false}) {
    if (!(result as any).hasError) {
      this.tableKey = (result as any).response.data
    }
    this.selectedCard = [result.response.data];
    this.showInputCard = false;

    this.$emit("after");
  }
}
