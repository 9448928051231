

































import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class SoundTherapyDMVDeleteTab extends Vue {
  name = "SoundTherapyDMVDeleteTab";

  headers = [
    // {text: "ID", value: "id"},
    {text: "ファイル名", value: "fileName"},
    {text: "ジャンル", value: "genres"},
    {text: "曲名", value: "title"},
    {text: "作曲家", value: "composer"},
    {text: "美容効果", value: "effects"},
    {text: "施術部位", value: "bodyParts"},
    {text: "表情種別", value: "cluster"},
    {text: "LINK", value: "path"}
  ];

  selected: {}[] = [];
  vibrationList: {}[] = [];

  diabledDelete = true;

  // dialog
  dialog = false;
  dialogMessage = "";

  mounted() {
    this.fetchDMVList();
  }

  fetchDMVList() {
    httpResource.get("/upload/sound-therapy-dmv-list")
        .then((response: any) => {
          this.vibrationList = response.data;
        });
  }

  deleteAction() {
    if (this.diabledDelete) {
      return;
    }

    const selected = this.selected[0] as any;

    httpResource.post(`/upload/delete-sound-therapy-dmv?id=${selected.id}`)
        .then((response: any) => {
          this.dialogMessage = "削除に成功しました。";
          this.selected = [];
          this.fetchDMVList();
        })
        .catch((error: any) => {
          // console.log(error)
          this.dialogMessage = "削除に失敗しました。";
        })
        .finally(() => {
          this.dialog = true;
        });
  }

  @Watch("selected")
  watchDiabledDelete() {
    this.diabledDelete = this.selected.length === 0;
  }
}
