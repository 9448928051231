<template>
  <v-card elevation="0">
    <v-card-title>申請</v-card-title>

    <v-card-text>
      申請を行います。
    </v-card-text>

    <visible-invisible-frame visibleName="一覧表示中" invisibleName="一覧非表示中">
      <v-data-table
          v-show="!hiddenList"
          v-model="selectedItems"
          :headers="header"
          :items="items"
          item-key="code"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :items-per-page="5"
          :single-select="true"
          class="elevation-0"
          :show-select="false"
      >
      </v-data-table>
    </visible-invisible-frame>

  </v-card>
</template>

<script>
import {Component, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class ApplyingList extends Vue {

  name = "ApplyingList";
  hiddenList = false;

  header = [
    // {text: '申請コード', value: 'code'},
    {text: '状態', value: 'status'},
    {text: '申請種別', value: 'type'},
    {text: '承認担当者', value: 'authorizer'},
    {text: '申請日', value: 'appliedDate'},
    {text: '承認日', value: 'approvedDate'},
  ];

  options = {}
  items = []
  selectedItems = []
  total = 0
  loading = false

  mounted() {
    this.getDataFromApi();
  }

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall().then((data) => {
      this.total = data.total;
      this.items = data.items;
    }).catch(error => {
      // NOP
    }).finally(() => {
      this.loading = false;
    })
  }

  async apiCall() {

    const {page, itemsPerPage} = this.options;

    const size = itemsPerPage == -1 ? this.total : itemsPerPage;
    if (size > 99) {
      alert('100件以上の表示はできません。')
      return;
    }

    // ページ更新するたびにtotal取得するが、動的に担当者がカルテを追加した場合を想定している
    const totalResponse = await httpResource.get(`applying/statuses-count`);
    const caltesResponse = await httpResource.get(`applying/statuses?page=${page - 1}&size=${size}`);

    return {items: caltesResponse.data, total: totalResponse.data};
  }
}
</script>

<style scoped>

</style>