







import {Component, Vue} from "vue-property-decorator";
import SelectCustomerStep from "@/components/domains/account/transfer/customer/steps/SelectCustomerStep.vue";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import TransferAgencySelectionStep
  from "@/components/domains/account/transfer/common/steps/TransferAgencySelectionStep.vue";
import AccountTransferVerificationStep
  from "@/components/domains/account/transfer/common/steps/AccountTransferVerificationStep.vue";

@Component
export default class CustomerAccountTransfer extends Vue {
  name = "CustomerAccountTransfer";
  steps: Step[] = [];
  createdStep = false;

  mounted() {
    this.steps = [
      new SelectCustomerStep(),
      new TransferAgencySelectionStep(),
      new AccountTransferVerificationStep()
    ];
    this.createdStep = true;
  }
}
