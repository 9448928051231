




















import {Component, Vue} from "vue-property-decorator";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import SoundEsthePurposeService from "@/components/domains/esthe/soundesthe/SoundEsthePurposeService";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";

@Component({
  components: {BackBtn, MyPageContentTemplate, ContentTitle, KawaiiButton, HoverLinkCard}
})
export default class SoundEsthePurposeDetail extends Vue {

  name = "SoundEsthePurposeDetail";
  details: {}[] = [];
  title!: string;

  created() {
    const code = this.$route.params.code;
    switch (code) {
      case '01': {
        this.title = '美容';
        break;
      }
      case '02': {
        this.title = '起床・就寝';
        break;
      }
      case '03': {
        this.title = 'ヘルスケア';
        break;
      }
      case '04': {
        this.title = 'デスクワーク';
        break;
      }
    }
    SoundEsthePurposeService.fetchPurposeDetails(code)
        .then((response: any) => {
          this.details = response.data;
        });
  }


  get getTitle() {
    return this.title;
  }
}
