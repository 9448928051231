









import {Component, Vue} from "vue-property-decorator";

@Component
export default class HpText extends Vue {
  name = "HpText"
}
