







































import {Component, Vue} from "vue-property-decorator";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import MentalCheckInductionCard from "@/components/domains/mentalcheck/MentalCheckInductionCard.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {
    PrimaryBtn,
    MentalCheckInductionCard, MyPageContentTemplate, StopWatchSoundPlayer: DMVPlayer, KawaiiButton
  }
})
export default class SoundEstheRandomPlan extends Vue {

  name = "SoundEstheRandomPlan";
  soundId = '';
  soundSrc = '';
  soundTitle = '';
  soundGenre = '';
  tags = [];
  playing = false;
  playingDateTime = new StopWatchDateTime();

  beforeMount() {

    httpResource.get('/soundesthe/random')
    .then((response: any) => {
      const data = response.data;
      this.soundId = data.id;
      this.soundSrc = data.soundSrc;
      this.soundTitle = data.title;
      this.soundGenre = data.genre;
      this.tags = data.tags;
    })
    .catch((error: any) => {
      alert('振動選択に失敗しました。');
    });
  }

  startStopListener(playing: boolean) {
    this.playing = playing;
  }

  receiveDateTimeOnStopped(id: string, dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
  }

  finish() {

    const request = {
      dmvTypeCode: "02",
      planCode: Plan.RANDOM.code,
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/soundesthe/");
  }

  get getSoundSrc() {
    return this.soundSrc;
  }
}
