import {User} from "@/components/domains/authorization/user";

export interface AuthState {
    authenticated: boolean;
    currentUser: User;
    intervalName: string;
    myPageLinks: {}[];
}

export enum AuthGetterTypes {
    getIsAuthenticated = 'getIsAuthenticated',
    getCurrentUser = 'getCurrentUser',
    getIntervalName = 'getIntervalName',
    getMyPageLinks = 'getMyPageLinks'
}

export enum AuthActionTypes {
    logout = 'logout',
    performLogout = 'performLogout',
    login = 'login',
    verifyLogIn = 'verifyLogIn',
    refreshTokenInterval = 'refreshTokenInterval',
    authenticateUser = 'authenticateUser',
    hasRole = 'hasRole',
    hasAuthority = 'hasAuthority',
    refreshMyPageLinks = 'refreshMyPageLinks'
}

export enum AuthMutationTypes {
    setAuthenticated = 'setAuthenticated',
    setCurrentUser = 'setCurrentUser',
    setIntervalName = 'setIntervalName',
    clearCurrentUser = 'clearCurrentUser',
    setMyPageLinks = 'setMyPageLinks'
}
