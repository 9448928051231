




















import {Component, Vue} from "vue-property-decorator";

@Component
export default class EstheRank extends Vue {
  name = "EstheRank";
  ex = 25;
}
