










import {Component, Vue} from "vue-property-decorator";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";

@Component({
  components: {HoverLinkCard}
})
export default class AIEstheHistory extends Vue {
  name = "AIEstheHistory";
}
