import QuestionData from "@/components/infrastructures/functions/questionnaire/questionData";
import {QuestionnaireSelection} from "@/components/infrastructures/functions/questionnaire/questionnaireSelection";
import {StressLevel} from "@/components/domains/stress_check/stressLevel";

export default class StressCheckSpecification {

    public static allAnswered(questions: QuestionData[]): boolean {
        return questions.find(q => q.selection === QuestionnaireSelection.UNANSWERED) === undefined;
    }

    public static aggregateStressQuestionnaire(questions: QuestionData[]): number {
        let score = 0;
        for (const question of questions) {

            switch (question.selection) {
                case QuestionnaireSelection.SOMETIMES:
                    score += 1;
                    break;
                case QuestionnaireSelection.APPLICABLE:
                    score += 2;
                    break;
                case QuestionnaireSelection.NOT_APPLICABLE:
                case QuestionnaireSelection.UNANSWERED:
                default:
                    break;
            }
        }
        return score;
    }

    public static stressLevel(score: number): StressLevel {

        if (score <= 14) {
            return StressLevel.LEVEL1;
        }
        if (score <= 24) {
            return StressLevel.LEVEL2;
        }
        if (score <= 34) {
            return StressLevel.LEVEL3;
        }
        return StressLevel.LEVEL4;
    }

    public static comment(level: StressLevel) {

        switch (level) {
            case StressLevel.LEVEL1:
                return 'ストレス度は高くなく､問題はないようです｡';
            case StressLevel.LEVEL2:
                return '中等度のストレスで､疲労気味';
            case StressLevel.LEVEL3:
                return '疲労状態で集中力が低下気味';
            case StressLevel.LEVEL4:
                return '重度の疲労状態です。';
            default:
                return 'ストレス計測がうまくできませんでした。';
        }
    }
}