









































import {Component, Vue} from "vue-property-decorator";
import PageZoomScaleButton from "@/components/infrastructures/atoms/PageZoomScaleButton.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import LangSelection from "@/components/infrastructures/molecules/LangSelection.vue";

@Component({
  components: {LangSelection, KawaiiButton, PageZoomScaleButton}
})
export default class Header extends Vue {
  name = "DefaultHeader.vue";
  version = process.env.VUE_APP_VERSION;

  public static links = [
    {'name': 'ホーム', 'link': '/'},
    {'name': '動作環境', 'link': '/environment'},
    {'name': 'サポート・お問い合わせ', 'link': '/support'},
  ];

  get getLinks() {
    return Header.links;
  }
}

