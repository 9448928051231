






import {Component, Vue} from "vue-property-decorator";
import NoticeBanner from "@/components/infrastructures/atoms/designed/NoticeBanner.vue";
import ActivityService from "@/components/domains/activity/ActivityService";

@Component({
  components: {NoticeBanner}
})
export default class ActivityNoticeBanner extends Vue {
  name = "ActivityNoticeBanner";
  text = '';

  beforeMount() {
    ActivityService.getActivityNotice()
        .then(advice => {
          this.text = advice.message
              + 'くすみについては、' + advice.dullnessElimination
              + '若返り効果については、' + advice.rejuvenationAdvice
              + '魅力アップについては、' + advice.attractivenessUp;
        })
        .catch(() => {
          // NOP
        });
  }
}
