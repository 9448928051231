






import {Component, Vue} from "vue-property-decorator";

@Component
export default class ResponsiveYouTube extends Vue {
  name = "ResponsiveYouTube";
}
